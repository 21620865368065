import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../config";
import { useStateContext } from "../StateProvider";
import { dicoWPSupa, supabase } from "../../V2/screens/testScreen";
import { DynamicSVG } from "../../V2/components/interactiveDialog";
import { useEffect, useState } from "react";
import { InteractiveDialog } from "./InteractiveDialog";


const darkMode = true

const getSc = async (wp_id, wp_id2 = "français") => {
    console.log('getSc 🟢', wp_id)
    const q = query(
      collection(db, 'scenarios'),
      where("wp_id", "==", "français")
    );
    const querySnapShot = await getDocs(q);
    const _uw = querySnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('_uw', _uw)

    const dataToInsert = _uw.map(e => ({
        name: e.name,
        content: e.content,
        people: e.people,  
        face: e.face,
        place: e.place,
        body: e.body,
        bg: e.bg,
        old_id: e.id,
        original_old_id: e.original,
        workspace_id: 97,
    })).filter(e => e.workspace_id)

    console.log("dataToInsert", dataToInsert)
        const { data, error } = await supabase
        .from('scenarios')
        .insert(dataToInsert)

    if (error) {
        console.error('Error inserting users:', error)
    } else {
        console.log('All users have been added successfully')
    }
    return _uw;
  }


//   const Message = ({mess, darkMode, selectedScenario, ct, classUser, classOther, currentUserChat, messages, setMessages, vocalMode}) => {

//     const [showTrad, setShowTrad] = useState(false)
//     const [revealMsg, setRevealMsg] = useState(true)
//     const [dataSpeak, setDataSpeak] = useState()
//     const [isSpeaking, setIsSpeaking] = useState(false)
  
//     const [currentMess, setCurrentMess] = useState(mess)
//     const [loadingTrad, setLoadingTrad] = useState(false)
//     const [loadingReformulation, setloadingReformulation] = useState(false)
  
//     useEffect(() => {
//       setCurrentMess(mess)
//     }, [mess])
  
//     useEffect(() => {
  
//         setRevealMsg(!vocalMode)
    
//     }, [vocalMode])
  
  
//     const handleReformulation = async () => {
  
//       console.log('inject msg')
//       console.log('mess', mess)
//       console.log('messages', messages)
//       setloadingReformulation(true)
//       const query = [
//         {"role": "user", "content": `Donne uniquement la réponse. Reformule ce message dans un ${workspace.name} naturel "${mess.content}"`}
//     ]
//       const newText = await gpt(query, true, ct)
//       console.log('newText', newText)
//       const updatedMessages = messages.map(m => m == mess ? {...m, ia_updated:  newText.choices[0]?.message.content} : m)
  
//       setMessages(updatedMessages);
//       setloadingReformulation(false)
//       // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})
  
  
  
  
   
   
//     }
  
  
//     const handleTrad = async() => {
  
//       if (!currentMess.trad) {
//         console.log('pas de traduction !')
//         const query = [
//           {"role": "user", "content": `Donne uniquement la réponse. traduit "${mess.content}" en français`}
//       ]
//         setLoadingTrad(true)
//         const tradData = await gpt(query, true, ct)
//         setLoadingTrad(false)
//         const tradText = tradData.choices[0]?.message.content
//         console.log('tradText', tradText)
//         setCurrentMess({...currentMess, trad: tradText})
//         const updatedMessages = messages.map(m => m == mess ? {...m, trad: tradText } : m)
//         setMessages(updatedMessages);
//         setShowTrad(true)
//         // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})
        
  
  
//       } else {
//         setShowTrad(t =>!t)
//         console.log('trad !!??')
//       }
      
//     }
  
  
//     const maxCharacter = mess.content?.length
  
//     // ajoute mess.ug user generated pour ajoute les actions ['reformulation']
  
//     return <div onClick={() => {
//       console.log('selectedScenario', selectedScenario)
//     }} className={`${mess.role == "user" ? "justify-end text-white ml-4" : "mr-4"} transition-all relative flex gap-2`}>
//          <div className=''>
//         <div className={`flex transition-all relative ${mess.role == "user" ? "justify-end text-white "  : ""}`}><div className={`rounded-xl border-[2px] ${mess.role == "user" ? classUser:classOther } ${mess.role == "user" ? 
//         mess.ia_updated ? `text-xs pb-2  
//           ${darkMode ? " text-white/80 bg-white/20 border-transparent": "text-blue-500  bg-white/70"}  
//           text-right justify-end self-end` : `self-end rounded-tr-[2px] bg-blue-500 border-blue-700 text-right justify-end` : `rounded-tl-[2px] w-full ${vocalMode ? "pr-8" : ""}  ${darkMode ? "bg-[#5e00c9]  text-white border-black/20":" bg-white text-slate-700"}  `} min-w-[40px] max-w-[90vw] px-[8px] py-[6px] overflow-hidden border-b-[4px] mb-3 `}>
//         {/* <SuperEditor className="text-xl pb-0" onBlur={() => {console.log('ee')}} content={markdownToHtml(mess.content)?.replaceAll('\n', '<br/> ---')} /> */}
        
//           {vocalMode && <div>
//             <div className='flex items-center gap-2'>
//           {/* <div>{dataSpeak?.["_duration"]}s</div> */}
//             <div className='' onClick={async() => {
//               if (isSpeaking) {
//                 stopSound()
//                 setIsSpeaking(false)
//               return
//             }
//               setIsSpeaking(true)
//               const dataSpeak = await speak({text: removeEmojis(mess.content), lang: workspace?.lang, setDataSpeak })
//               setIsSpeaking(false)
//               console.log(dataSpeak)
//             }
//               }>
//                 {isSpeaking ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
//     <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
//   </svg>
//   : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
//                   <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
//                 </svg>}
//   </div>
//   <div className={`h-3 ${isSpeaking ? "animate-pulse" : ""}  w-full bg-white/50 rounded-full`}></div>
  
  
//             </div>
//             <div className='text-xs text-transparent h-[5px] max-h-[5px] pr-8 '>
//               {!revealMsg && mess.content?.slice(0, maxCharacter < 70 ? maxCharacter : 70) + '...'}
//               <div className='absolute right-2 top-2 text-white/70' onClick={() => {setRevealMsg(t => !t)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${revealMsg ? "rotate-[180deg]" : ""}`}>
//     <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
//   </svg>
//   </div>
//               </div>
//             </div>}
//           {revealMsg &&  <SuperText 
//                           vocalMode={false} 
//                           colorText={false}
//                           text={numberSentences(mess.content)} 
//                           imgContext={selectedScenario?.bg}
//                           trad={numberSentences(mess.trad)} 
//                           decks={[]} 
//                           cards={ct.cards}  
//                           ct={ct}/>}
  
//             {!vocalMode && <div id="helper" className={`${mess.role == "user" ? "justify-end" : ""} flex mt-2 items-center`}>
//               {isSpeaking ? <l-waveform size="18" speed="1.6"   color="#80746B4D" ></l-waveform> : <svg onClick={async() => {
//                 setIsSpeaking(true)
//                 await speak({text: removeEmojis(mess.content), lang: workspace?.lang})
//                 setIsSpeaking(false)
//                 }}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
//                 <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
//               </svg>}
//                 {mess.ug ? !mess.ia_updated && <div className='px-2 text-xs opacity-[0.7] rounded-xl' onClick={async() => {handleReformulation() }} >Reformuler {loadingReformulation && "..."} </div> : <div onClick={() => handleTrad()} className={`${showTrad ? "bg-amber-100/30" : ""} px-2 text-xs opacity-[0.7] rounded-xl`}>Voir la traduction  {loadingTrad && "..."}</div>}
//               </div>}
//               {currentMess.trad && showTrad && <div className={`transition-all text-slate-500 ${darkMode ? "bg-black/30- text-white/50-" : "bg-slate-100/60- text-slate-500-"} text-xs italic   p-2 pt-1 mt-2 -mx-2 -mb-2   ${vocalMode ? "!-mr-8  " : ""}`}>{mess.trad}</div>}
  
//             </div>
  
//           </div>
//           {mess.ia_updated && <div className='bg-emerald-500 text-right pl-6 relative rounded-xl border-[2px] border-black/20 border-b-[4px] mb-3 -mt-[20px] p-2 pt-1  -mx-2 -mb-2'>
//           <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4 absolute top-1 left-1">
//     <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
//   </svg>
//             {mess.ia_updated}
//             </div>}
  
      
   
  
         
  
      
            
//              </div>
//     </div>}

  const getScenarios = async (workspace_id, set = () => {}) => {
    try {
        const { data, error } = await supabase
            .from('scenarios')  // Nom de votre table
            .select('*')
            .or(`workspace_id.eq.${workspace_id},workspace_id.eq.97`)  // Filtre OR pour les deux conditions

        if (error) {
            throw error;
        }

        console.log('data', data)
        set(data);
        return data;
    } catch (error) {
        console.error('Erreur lors de la récupération des scénarios:', error);
        throw error;
    }
}


const RenderScenarioLine = ({scenario, setSelectedScenario}) => {

    return <div 
    style={{backgroundImage: `url(${scenario.bg})`}}
    onClick={() => {
    //   if (scenario.id == "free") {setIndexShow("free")}
    //    else {
    //     const tradChat = scenarios.find(x => x.id == scenario.original)?.content
    //     setselectedScenario({...e, tradContent: tradChat})
    //     setShowInfo(true)
    //   }
    
    setSelectedScenario(scenario)

      }} className={`p-3 ${scenario.id == "free" ? "bg-blue-900/20" : "bg-black/20"} text-shadow relative overflow-hidden bg-center bg-cover border-black/70  mb-2 flex gap-2 rounded-xl border-2 border-b-4`}>
        <div className='absolute top-0 z-0 left-0 right-0 bottom-0 bg-indigo-500/50'></div>
       
        {/* <div className='absolute -top-2 -left-1 bg-indigo-500/50 rounded-full h-[40px] w-[40px] flex text-xl items-center justify-center'>{scenario.emo}</div> */}
        <div className='relative z-10'> 
          <DynamicSVG  src={"/images/UX/peeps/head/"+scenario.face+".svg"} className=" -mt-2  relative  h-[40px] w-[40px] text-[#0e0c3e]"/>
          <img  src={"/images/UX/peeps/face/Calm.svg"} className='h-[78%] absolute top-[12%] left-[29%]' />
          <img  src={`/images/UX/peeps/body/${scenario.body}.svg`} className='h-[40px] absolute top-[82%] left-[-9%]' />


        </div>
  
        <div className='relative z-20 font-bold truncate' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
  {scenario.name}
</div>
       
      </div>
}

const RenderScenario = ({selectedScenario, setSelectedScenario, setSelectedMode, selectedMode}) => {

    const {workspace, scenarios} = useStateContext()
    const [vocalMode, setVocalMode] = useState()
    const [audioMode, setAudioMode] = useState()
    const [isWriting, setIsWriting] = useState(false)
    const traductionScenario = scenarios.find(e => e?.old_id == selectedScenario?.original_old_id)


    console.log('traductionScenario', traductionScenario)



    return  <div id="scroll" className={`h-screen fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} z-[10] overflow-scroll `}>
 
 
 
    <header className={`flex ${darkMode ? "bg-[#270742]/80/10 bg-blur-xl border-black/30" :"bg-[#fefdf3]" } items-center z-10 fixed top-0 right-0 left-0 items-center  px-2 py-4 justify-between gap-2`}>
       <div className='flex  gap-2 items-center'>
       <svg onClick={() => {setSelectedScenario(); setSelectedMode()}}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className=" p-2 min-h-[40px] min-w-[40px] -ml-[6px] h-[40px] w-[40px]">
        <path fillRule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clipRule="evenodd" />
      </svg>
  
          {/* <img src={selectedScenario.photo} className='h-[36px]'/> */}
          <div className={`${darkMode ? "text-white" : ""} text-sm`}>{selectedScenario?.name?.[`${workspace?.lang}`] || selectedScenario?.name}</div>
          <span onClick={() =>selectedScenario(true)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-5 w-5 ${darkMode ? "text-white/50" : "text-slate-500"} mt-1 `}>
    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
  </svg>
  </span>
          {/* <div className='text-slate-300'>[{ct.userWorkspace.total_tokens}] | {ct.userDaily?.total_tokens}</div> */}
         
       </div>
      
       <div className='grow flex justify-end'>
            <div className='flex bg-black/20 border-2 border-black/40 overflow-hidden rounded-xl gap-1'>
              <svg onClick={() => {setVocalMode(false)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 p-[3px] px-[5px] rounded-md ${!vocalMode ? "text-indigo-100 bg-black/30" : "text-white/40"}`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
              </svg> 
              <svg onClick={() => {setVocalMode(true)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 p-[3px] px-[5px] rounded-md ${vocalMode ? "text-indigo-100 bg-black/30" : "text-white/40"}`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
              </svg>
            </div>
        </div>
       
  
  
       
  
       </header>
       <div className='fixed top-0 left-0 right-0 h-[100px] bg-gradient-to-b from-black/70 via-black/50 to-black/10'></div>
       <div className={`${darkMode ? "" : "bg-yellow-50"} overflow-hidden  max-h-screen h-screen `}>
  
        <InteractiveDialog selectedMode={selectedMode}  vocalMode={vocalMode}  selectedScenario={selectedScenario} trad={JSON.parse(traductionScenario?.content)} content={JSON.parse(selectedScenario?.content)}  />
       
  
  
      
  
        
         {/* {messages?.map((mess) => <Message selectedScenario={selectedScenario} messages={messages} vocalMode={vocalMode} currentUserChat={currentUserChat} setMessages={setMessages} ct={ct} darkMode={true} mess={mess}/>)} */}
        
       </div>
  
  
       {false && <div className={`fixed left-0 ${darkMode ? "bg-[#370e57]" : "bg-yellow-50"} pt-2 right-0 bottom-[50px] pb-1 transition-all px-4 mb-4 ${!isWriting && !audioMode ? "pr-[80px]":  "pr-[2px]"} `}>
       {audioMode && <svg onClick={() => setAudioMode()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-10 ${darkMode ? "text-blue-100" : "text-blue-500"}  rounded-full absolute -left-0 bottom-6`}>
    
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
  
  
        </svg>}
        
  
       
      </div>}
    </div>
}


const RenderScenarioOptions = ({selectedScenario, setSelectedScenario,  setSelectedMode}) => {
    

  const mode = [{
    id: "interactif",
    name: "Dialogue interactif",
    desc: "Découvre le dialogue et choisis les bonnes réponses."
  }, 
  {
    id: "expression",
    name: "Prononciation",
    desc: "Travaille ton expression orale et écrite en répondant de manière guidée.",
    // soon: true,
  }, 
  {
    id: "open",
    name: "Discussion libre",
    desc: "Discute librement à l'oral ou à l'écrit. C’est le meilleur moyen pour apprendre à converser dans une discussion réelle."
  }
]




return <div style={{backgroundImage: `url('${selectedScenario.bg}')`}}  className={`h-screen bg-center  bg-cover z-[61] fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} p-4 flex flex-col text-center gap-2 items-center justify-center`}>
     <div onClick={() => setSelectedScenario()} className='w-full fixed top-4 left-4 text-left text-slate-500'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-6 text-white ">
        <path fillRule="evenodd" d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z" clipRule="evenodd" />
        </svg>
        </div>
            <div className='absolute pointer-events-none z-[-1] top-0 left-0 right-0 bottom-0 bg-[#3c2777b3]'></div>
            {/* <img src={selectedScenario.photo} className='h-[60px] mt-10'/> */}
            {/* <h2 className={`${darkMode ? "text-white" : ""}`}>{selectedScenario?.name?.[`${workspace?.lang}`] || selectedScenario?.name?.all}</h2> */}
            <div className={`${darkMode ? "text-white/60" : "text-slate-500"}  text-center`}>{selectedScenario?.description}</div>
            <div className='text-white' onClick={() => {console.log('selectedScenario', selectedScenario)}}>Choisis ton mode d'apprentissage</div>
            <div>{mode.map(e => <div onClick={() => {setSelectedMode(e.id)}} className={` ${e.soon ? "opacity-50 pointer-events-none	" : ""} px-3 bg-indigo-600/50 backdrop-blur-xl  game-btn border-2 border-b-4 border-black/70 py-2 pb-4 rounded-xl mt-2`}>
            <div className='mb-1 font-semibold'>{e.name}</div>
            <div className='text-xs text-white/70'>{e.desc}</div>
            </div>)}</div>
            {/* {currentUserChat && <div className={`${darkMode ? "text-red-300/90" : "text-red-900 "} text-center my-4 `} onClick={( ) => resetChat()}>Effacer la discussion</div>}
            {!currentUserChat ? <div onClick={() => {setShowInfo(); createUserChat()}} className='px-4 bg-purple-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Commencer à discuter</div> : 
            <div onClick={() => setShowInfo()} className='px-4 bg-emerald-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Reprendre la discussion</div>} */}
    </div>  
}

const ScenarioScreen = () => {
    const {workspace, scenarios, setShowTopBar, setScenarios} = useStateContext()

    const [selectedScenario, setSelectedScenario] = useState()
    const [selectedMode, setSelectedMode] = useState()

    useEffect(() => {
        scenarios.length == 0 && getScenarios(workspace?.id, setScenarios)
        setSelectedScenario()
        setSelectedMode()
    }, [])


    useEffect(() => {
        setShowTopBar(!selectedScenario)
    }, [selectedScenario])

    


    return <div className="text-white p-4 pt-8">
        {selectedScenario && selectedMode && <RenderScenario selectedMode={selectedMode} setSelectedMode={setSelectedMode} selectedScenario={selectedScenario} setSelectedScenario={setSelectedScenario} />}

        {selectedScenario && !selectedMode && <RenderScenarioOptions setSelectedScenario={setSelectedScenario} selectedScenario={selectedScenario} setSelectedMode={setSelectedMode} />}
       

        {!selectedScenario && scenarios.filter(e => e.workspace_id == workspace?.id)?.map(e => {
            return <RenderScenarioLine setSelectedScenario={setSelectedScenario} scenario={e} />
        })}

   
    </div>
}

export { ScenarioScreen } 