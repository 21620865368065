import { useState } from "react"
import { useStateContext } from "../../StateProvider"
import { getCardToLearn } from "../../helperFunctions"
import { SVG_close } from "../../svgs"
import { gpt } from "../../../utilities/helper/text"
import { SuperText } from "../SuperText"
import { sbCreate } from "../../DbFunction"



const AddSentence = () => {
        const {  setShowModal, workspace,profile, setCardsToRefresh, cards, setCards, setLearnedCards, learnedCards } = useStateContext()

        const [isLoading, setIsLoading] = useState()
        const [answer, setAnswer] = useState()

        const _cardsToRefresh = getCardToLearn(learnedCards?.filter(e => !e.card?.term))?.slice(0, 20)

        return <div onClick={() => {setShowModal()}} className="bg-black/50 absolute fixed z-[999] top-0 left-0 right-0 bottom-0">
                <div onClick={(e) => {e.stopPropagation()}} className="p-8 max-h-[80vh] overflow-scroll text-center rounded-xl bg-blue-600 text-white m-8">
                <SVG_close onClick={() => {setShowModal()}}/>
                Créer une phrase à apprendre en {workspace?.name}
                <div className="my-4 mt-8">
                    <textarea id="to-translate" placeholder="Ecris ta phrase en français" className="rounded-xl block w-full p-2 border-2 text-purple-500"></textarea>
                    <div onClick={async() => {
                        const toTranslateText = document.querySelector('#to-translate')?.value
                        setIsLoading(true)
                      
                     
                        const text_request = `Donne uniquemnent la réponse sans markdown. Traduis la phrase suivante de manière naturelle en ${workspace?.name}: ${toTranslateText}
                        `
                        const request = [{ "role": "user", "content": text_request}]
                        const requestData = await gpt(request, true, null, {model: "gpt-4o", temp: 1.2})
                       
                        setAnswer(requestData.choices[0]?.message?.content)
                        setIsLoading(false)

                    }} className="rounded-xl px-4 py-2 bg-purple-100 text-purple-500 mt-4">{isLoading ? "Traduction en cours ... " : "Traduire"}</div>
                    {answer && <div className="bg-green-500 text-white rounded-xl mt-4">
                      
                        <div className='font-semibold text-2xl mt-8 mb-2 text-slate-800'>{
                <SuperText 
                tradMode={false} 
                imgContext={false}
                path={"news"}
                colorText={false}
                text={answer} 
                darkMode={false}
                trad={""} 
                decks={[]} 
                //   cards={ct.cards}  
                ct={null}/>
              
       
        }

            <div className="flex gap-2 text-xs px-2 p-2 font-[400]">
                 
                    <div className="px-3 px-2 text-base border bg-white rounded-md border-b-2 " onClick={async() => {
                        const toTranslateText = document.querySelector('#to-translate')?.value
                        const card = {
                            model: "lang",
                            context: answer,
                            workspace_id: workspace?.id,
                            context_fr: toTranslateText,
                            data: {type: "sentence"}
                        }

                        const profile_card = {
                            lvl: 0,
                          
                            last_update: new Date(),
                            collected: false,
                            next_date: new Date(),
                            first_date: new Date(),
                            next_trigger: "0",
                            profile_id: profile.id,
                            workspace_id: workspace.id,
                            triggers: [
                                {next_date: new Date(),lvl: 1},
                                {next_date: new Date(),lvl: 0},
                        ]
                        }

                        console.log('newCard', card)
                        const cardDB = await sbCreate('cards', card)
                        console.log('cardDB', cardDB)
                        const profile_cardDB = await sbCreate('profiles_cards', {...profile_card, card_id: cardDB?.id})
                        console.log('profile_cardDB', profile_cardDB)
    
                        setLearnedCards(prev => [...prev, {card: cardDB, profile_card: profile_cardDB}])
                       
                    }}>Ajouter</div>
                </div>


                
        </div>
                        </div>}
                        <div className="px-4 p-1 rounded-xl mt-2 border border-white/20 border-b-4 bg-white/10" onClick={() => {
                    //    setCardsToDiscover(nextCards?.slice(0,4 - cards?.length)) ;

                        console.log('learned cards', learnedCards)
   
                       setCardsToRefresh(_cardsToRefresh.slice(0,3));
                       setShowModal('learnCards')
                }}>Réviser phrase {_cardsToRefresh?.length}/{learnedCards?.filter(e => !e.card.term)?.length}</div>
                <div className="text-left">{learnedCards?.filter(e => !e.card?.term)?.map(e => <div className="mt-4" onClick={console.log('e', e)}>
                    <div className="flex items-center justify-between">
                    <div className="grow">
                        <div className="opacity-[80%] italic">{e.card.context_fr}</div>
                        <div>{e.card.context}</div>
                    </div>
                    <img className="h-12 rounded-xl" src={e.card.img || e.profile_card?.context?.img} />
                    </div>
                    </div>)}</div>
                </div>
                </div>
        </div>
}

export {AddSentence}