import { transformText } from "../../../utilities/helper/text";
import { useStateContext } from "../../StateProvider";
import { getTextBetweenBrackets } from "../../helperFunctions";

const HighlightText = ({brackedSentence, className, classNameText, deco}) => {

    const parts = brackedSentence?.split(/\[(.*?)\]/);
    const text = getTextBetweenBrackets(brackedSentence)

    const {text_50k} = useStateContext()

    
  
  
    return (<div className={className  + " "}>
     
            {parts?.map((part, index) => {
               
                if (index % 2 === 1) {
                    return <span className={classNameText}>{part} {deco}</span>;
                }
                return <>{part?.trim().split(' ').map(e => ({word: e, rank: text_50k?.indexOf(transformText(e))})).map(e => <span className={`${(e.rank < 3000 && e.rank != -1) ? "  opacity-[80%] underline-offset-4 font-[400] decoration-dashed" : "opacity-[67%] font-[400] text-black/70"}  mx-1 inline-block`}> {e.word}</span>)} </>;
            })}
        
        
        </div>
        
    );
  };

  export {HighlightText}