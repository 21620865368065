import { useEffect, useState } from "react";
import { centToStars, comparerSansAccents } from "../../../utilities/helper/helper";
import { mp3_bad, mp3_valid } from "../../helperData";
import { clean, getTextBetweenBrackets, hanziToPinyin, speak } from "../../helperFunctions";
import { useStateContext } from "../../StateProvider";
import { transformText } from "../../../utilities/helper/text";
const normalize = (str) => str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.toLowerCase();



function formatText(str) {
    return str.replace(/<b>(.*?)<\/b>/g, (match, content) => {
        const parts = content.split('/');
        if (parts.length !== 2) return content; // Cas non valide
        const afterSlash = parts[1];
        console.log('afterSlash', afterSlash)
        // if (!afterSlash) return `<b>${str}</b>`
        return '_'.repeat(afterSlash.length);
    });
}

function transformFeedback(feedback) {
    if (!feedback) return "";
    let transformed = feedback;
  
    // Remplacer <b>wrong/correct</b> par une chaîne sur une seule ligne
    transformed = transformed.replace(/<b>(.*?)<\/b>/g, (match, p1) => {
      const parts = p1.split("/");
      const wrong = parts[0] || "";
      const correct = parts[1] || "";
      return '<span class=" items-center">' +
             
             '<span class="text-pink-500 hidden opacity-[60%] line-through">' + wrong + '</span>' +
             '<span class=" text-green-500">' + correct + '</span>' +
             '</span>';
    });
  
    // Remplacer <p>…</p> : mot mal placé (affiché en jaune)
    transformed = transformed.replace(/<p>(.*?)<\/p>/g, (match, p1) => {
      return '<span class="bg-yellow-200 text-yellow-800  rounded">' + p1 + '</span>';
    });
  
    // Remplacer <d>…</d> : mot en trop (gris)
    transformed = transformed.replace(/<d>(.*?)<\/d>/g, (match, p1) => {
      return '<span class="text-gray-500">' + p1 + '</span>';
    });
  
    // Remplacer <i>…</i> : mot manquant (en bleu, italique)
    transformed = transformed.replace(/<i>(.*?)<\/i>/g, (match, p1) => {
      // Si la balise est vide, on affiche un placeholder (ici "____")
      const content = p1.trim() !== "" ? p1 : "____";
      return '<span class="text-blue-500 italic">' + content + '</span>';
    });
  
    return transformed;
  }


/**
 * Calcule la distance de Levenshtein entre deux chaînes.
 * @param {string} a 
 * @param {string} b 
 * @returns {number} la distance (nombre d'opérations nécessaires)
 */
function levenshtein(a, b) {
    const m = a.length, n = b.length;
    const dp = [];
    for (let i = 0; i <= m; i++) {
      dp[i] = [];
      dp[i][0] = i;
    }
    for (let j = 0; j <= n; j++) {
      dp[0][j] = j;
    }
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1,
          dp[i][j - 1] + 1,
          dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
        );
      }
    }
    return dp[m][n];
  }
  
  /**
   * Calcule la similarité normalisée entre deux mots (entre 0 et 1).
   * 1 signifie que les deux mots sont identiques.
   * @param {string} word1 
   * @param {string} word2 
   * @returns {number} valeur de similarité
   */
  function normalizedSimilarity(word1, word2) {
    const maxLen = Math.max(word1.length, word2.length);
    if (maxLen === 0) return 1;
    const dist = levenshtein(word1, word2);
    return 1 - (dist / maxLen);
  }
  
  /**
   * Renvoie un diff entre deux mots sous forme d'une chaîne avec la partie différente 
   * encadrée par une balise <b> sous la forme "partieInput/partieCorrection".
   * Si les mots sont identiques, renvoie le mot.
   * @param {string} inputWord 
   * @param {string} corrWord 
   * @returns {string}
   */
  function diffWord(inputWord, corrWord) {

    const cleanInput = inputWord.toLowerCase().normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")
    const cleanCorr = corrWord.toLowerCase().normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")

    if (cleanInput === cleanCorr) return corrWord;
    
    let i = 0;
    while (i < cleanInput.length && i < cleanCorr.length && (cleanInput[i]?.toLowerCase() === cleanCorr[i]?.toLowerCase())  ) {
      i++;
    }
    const prefix = cleanCorr.slice(0, i);
    
    let j = 0;
    while (
      j < (cleanInput.length - i) &&
      j < (cleanCorr.length - i) &&
      (cleanInput[cleanInput.length - 1 - j]?.toLowerCase() === cleanCorr[cleanCorr.length - 1 - j]?.toLowerCase() )
    ) {
      j++;
    }
    const suffix = j > 0 ? cleanCorr.slice(cleanCorr.length - j) : "";
    
    const inputDiff = cleanInput.slice(i, cleanInput.length - j);
    const corrDiff = cleanCorr.slice(i, cleanCorr.length - j);
    
    return prefix + "<b>" + inputDiff + "/" + corrDiff + "</b>" + suffix;
  }
  
  /**
   * Calcule le score et génère plusieurs feedbacks en comparant un input à une correction.
   * Retourne notamment :
   *  - richFeedback : feedback détaillé (balises <b>, <i>, <d>, <p>)
   *  - hintFeedback : feedback avec des placeholders
   *  - correctFeedback : la phrase de correction agrémentée des balises indiquant la faute
   *
   * La détection d'erreurs tient compte des substitutions (fautes d'orthographe),
   * des insertions (mots oubliés), des suppressions (mots en trop)
   * et des mots mal placés (détectés notamment via un appariement ou une inversion).
   *
   * @param {string} input La phrase saisie par l’utilisateur.
   * @param {string} correction La phrase de référence.
   * @returns {object} un récapitulatif du score, des statistiques et des feedbacks.
   */
  function scorePhrase(input, correction) {
    // Découpage en mots (on conserve la casse pour l'affichage,
    // mais pour la comparaison on utilisera toLowerCase())
    const inputWords = input.trim().split(/\s+/);
    const corrWords  = correction.trim().split(/\s+/);
    const m = inputWords.length;
    const n = corrWords.length;
    
    // --- Construction de la matrice DP ---
    const dp = [];
    for (let i = 0; i <= m; i++) {
      dp[i] = [];
      for (let j = 0; j <= n; j++) {
        dp[i][j] = { cost: 0, op: '' };
      }
    }
    for (let i = 1; i <= m; i++) {
      dp[i][0] = { cost: i, op: 'delete' };
    }
    for (let j = 1; j <= n; j++) {
      dp[0][j] = { cost: j, op: 'insert' };
    }
    
    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        const delCost = dp[i - 1][j].cost + 1;
        const insCost = dp[i][j - 1].cost + 1;
        const sim = normalizedSimilarity(inputWords[i - 1].toLowerCase(), corrWords[j - 1].toLowerCase());
        const subCost = dp[i - 1][j - 1].cost + (1 - sim);
        
        let minCost = delCost;
        let op = 'delete';
        if (insCost < minCost) { minCost = insCost; op = 'insert'; }
        if (subCost < minCost) { minCost = subCost; op = 'substitute'; }
        dp[i][j] = { cost: minCost, op: op };
      }
    }
    
    // --- Backtracking pour obtenir l’alignement ---
    // On enregistre pour chaque opération les indices dans l'input (iIndex)
    // et dans la correction (jIndex) quand c'est applicable.
    let alignment = [];
    let i = m, j = n;
    while (i > 0 || j > 0) {
      const cell = dp[i][j];
      if (i > 0 && j > 0 && cell.op === 'substitute') {
        alignment.unshift({
          type: 'substitute',
          input: inputWords[i - 1],
          corr:  corrWords[j - 1],
          iIndex: i - 1,
          jIndex: j - 1
        });
        i--; j--;
      } else if (j > 0 && cell.op === 'insert') {
        alignment.unshift({
          type: 'insert',
          corr: corrWords[j - 1],
          jIndex: j - 1
        });
        j--;
      } else if (i > 0 && cell.op === 'delete') {
        alignment.unshift({
          type: 'delete',
          input: inputWords[i - 1],
          iIndex: i - 1
        });
        i--;
      } else {
        break;
      }
    }
    
    // --- Appariez d'abord les insertions et suppressions quand le mot est identique ---
    for (let a = 0; a < alignment.length; a++) {
      if (alignment[a].type === 'insert' && !alignment[a].paired) {
        for (let b = 0; b < alignment.length; b++) {
          if (alignment[b].type === 'delete' && !alignment[b].paired) {
            if (alignment[b].input.toLowerCase() === alignment[a].corr.toLowerCase()) {
              alignment[a].type = 'missing';
              alignment[b].type = 'misplaced';
              alignment[a].paired = true;
              alignment[b].paired = true;
              break;
            }
          }
        }
      }
    }
    
    // --- Détection de transposition (mots inversés) ---
    // Si l'on observe une séquence [insert, delete, substitute] où le delete
    // correspond au mot attendu dans le insert, alors on considère que le mot manquant
    // est mal placé. On requalifie l'insertion en "missing" (avec paired=true)
    // et on marque la suppression pour être ignorée.
    for (let k = 0; k < alignment.length - 2; k++) {
      if (alignment[k].type === 'insert' && 
          alignment[k+1].type === 'delete' &&
          alignment[k+2].type === 'substitute') {
        // Si le mot supprimé correspond (en position ultérieure) au mot attendu de l'insertion
        if (alignment[k+1].input.toLowerCase() === alignment[k+2].corr.toLowerCase()) {
          alignment[k].type = 'missing';
          alignment[k].paired = true;
          alignment[k+1].skip = true; // on marquera cette suppression pour l'ignorer
        }
      }
    }
    
    // --- Construction des feedbacks ---
    
    // Pour les feedbacks, on part des opérations ayant un jIndex (les positions dans la correction)
    const corrOps = alignment.filter(op => typeof op.jIndex === "number");
    
    // 1. richFeedback (feedback détaillé, avec <b>, <i>, <d>, <p>)
    const richTokens = corrOps.map(op => {
      if (op.type === 'substitute') {
        const sim = normalizedSimilarity(op.input.toLowerCase(), op.corr.toLowerCase());
        // Ici, on applique un seuil pour décider d'afficher ou non le diff
        if (op.input.toLowerCase() === op.corr.toLowerCase() || sim >= 0.99) {
          return { token: op.corr, iIndex: op.iIndex, jIndex: op.jIndex };
        } else {
          return { token: diffWord(op.input, op.corr), iIndex: op.iIndex, jIndex: op.jIndex };
        }
      } else if (op.type === 'missing') {
        // S'il s'agit d'un mot manquant apparié (transposition ou misplacement), on marque avec <p>
        if (op.paired) {
          return { token: "<p>" + op.corr + "</p>", iIndex: null, jIndex: op.jIndex };
        } else {
          return { token: "<i></i>", iIndex: null, jIndex: op.jIndex };
        }
      }
      // Pour un insert non reclassé, on affiche le mot attendu
      return { token: op.corr, iIndex: null, jIndex: op.jIndex };
    });
    
    // Insertion des tokens issus des suppressions reclassées en "misplaced"
    const richMisplaced = alignment.filter(op => op.type === 'misplaced' && !op.skip);
    let finalRichTokens = richTokens.slice();
    richMisplaced.forEach(misOp => {
      // On insère le mot mal placé (de l'input) dans une position déterminée par son iIndex
      let insertPos = finalRichTokens.length;
      let maxInputIndex = -1;
      for (let k = 0; k < finalRichTokens.length; k++) {
        const tokenObj = finalRichTokens[k];
        if (tokenObj.iIndex !== null && tokenObj.iIndex <= misOp.iIndex && tokenObj.iIndex > maxInputIndex) {
          maxInputIndex = tokenObj.iIndex;
          insertPos = k + 1;
        }
      }
      finalRichTokens.splice(insertPos, 0, { token: "<d>" + misOp.input + "</d>", iIndex: misOp.iIndex });
    });
    const richFeedback = finalRichTokens.map(tok => tok.token).join(" ");
    
    // 2. hintFeedback (placeholders)
    const hintTokens = corrOps.map(op => {
        // console.log('op', op)
        if (op.type === 'substitute') {
          const sim = normalizedSimilarity(op.input.toLowerCase(), op.corr.toLowerCase());
          if (op.input.toLowerCase() === op.corr.toLowerCase() || sim >= 0.99) {
            // Si le mot est correct ou quasiment correct, on l'affiche tel quel.
            return { token: op.corr, iIndex: op.iIndex, jIndex: op.jIndex };
          } else {
            // Sinon, on affiche autant d'underscores que de lettres dans le mot attendu.
            return { token: op.input.length > op.corr?.length ? "<p>" + op.input + "</p>": formatText(diffWord(op.input, op.corr)), iIndex: op.iIndex, jIndex: op.jIndex };
          }
        } else if (op.type === 'missing') {
          // Pour un mot manquant, on affiche des underscores en fonction de la longueur du mot attendu.
          return { token: "_".repeat(op.corr.length), iIndex: null, jIndex: op.jIndex };
        } else if (op.type === 'insert') {
            // console.log('!!op', op)
            // Pour un mot manquant, on affiche des underscores en fonction de la longueur du mot attendu.
            return { token: "_".repeat(op.corr.length), iIndex: null, jIndex: op.jIndex };
          
          } 

        // Pour les autres types (par exemple les insertions non reclassées),
        // on retourne directement le mot de la correction.
        return { token: op.corr, iIndex: null, jIndex: op.jIndex };
      });
    let finalHintTokens = hintTokens.slice();
    richMisplaced.forEach(misOp => {
      let insertPos = finalHintTokens.length;
      let maxInputIndex = -1;
      for (let k = 0; k < finalHintTokens.length; k++) {
        const tokenObj = finalHintTokens[k];
        if (tokenObj.iIndex !== null && tokenObj.iIndex <= misOp.iIndex && tokenObj.iIndex > maxInputIndex) {
          maxInputIndex = tokenObj.iIndex;
          insertPos = k + 1;
        }
      }
      finalHintTokens.splice(insertPos, 0, { token: "____", iIndex: misOp.iIndex });
    });
    const hintFeedback = finalHintTokens.map(tok => tok.token).join(" ");
    
    // 3. correctFeedback : affichage de la correction avec balises, en forçant l'affichage du diff pour les substitutions
    const correctTokens = corrOps.map(op => {
      if (op.type === 'substitute') {
        // Ici, si les mots diffèrent (même légèrement), on affiche toujours le diff
        if (op.input.toLowerCase() !== op.corr.toLowerCase()) {
          return { token: diffWord(op.input, op.corr), iIndex: op.iIndex, jIndex: op.jIndex };
        } else {
          return { token: op.corr, iIndex: op.iIndex, jIndex: op.jIndex };
        }
      } else if (op.type === 'missing') {
        if (op.paired) {
          return { token: "<p>" + op.corr + "</p>", iIndex: null, jIndex: op.jIndex };
        } else {
          return { token: "<i>" + op.corr + "</i>", iIndex: null, jIndex: op.jIndex };
        }
      }
     else if (op.type === 'insert') {
        // console.log('!!op', op)
        // Pour un mot manquant, on affiche des underscores en fonction de la longueur du mot attendu.
        return { token: "<i>" + op.corr + "</i>", iIndex: null, jIndex: op.jIndex };
      } 
      
      return { token: op.corr, iIndex: null, jIndex: op.jIndex };
    });
    // Pour correctFeedback, nous n'insérons pas séparément les suppressions mal placées,
    // car elles sont déjà traitées via le reclassement en "missing" (paired).
    const correctFeedback = correctTokens.map(tok => tok.token).join(" ");
    
    // --- Calcul des statistiques et du score ---
    let correctCount = 0, partialCount = 0, errorCount = 0, missingCount = 0, extraCount = 0, misplacedCount = 0;
    alignment.forEach(op => {
      if (op.type === 'substitute') {
        if (op.input.toLowerCase()?.replace(/[?;",!]/g, '') === op.corr.toLowerCase()?.replace(/[?;",!]/g, '') || comparerSansAccents(op.input.toLowerCase(), op.corr.toLowerCase())) {
          correctCount++;
        } else {
          const sim = normalizedSimilarity(op.input.toLowerCase()?.replace(/[?;,"!]/g, ''), op.corr.toLowerCase()?.replace(/[?;,"!]/g, ''));
    
          if (sim >= 0.99) {
            correctCount++;
          } else if (sim >= 0.49) {
            partialCount++;
      
          }
           else {
            errorCount++;
          }
        }
      } else if (op.type === 'missing') {
        missingCount++;
      } else if (op.type === 'delete') {
        extraCount++;
      } else if (op.type === 'misplaced') {
        misplacedCount++;
      }
    });
    const totalExpected = corrWords.length + extraCount;
    const points = correctCount + (partialCount * 0.6);
    console.log('correctCount: ',correctCount)
    const scorePercentage = totalExpected > 0 ? (points / totalExpected) * 100 : 100;
    
    return {
      score: scorePercentage,
      correct: correctCount,
      partial: partialCount,
      errors: errorCount,
      missing: missingCount,
      extra: extraCount,
      misplaced: misplacedCount,
      alignment: alignment,
      richFeedback: richFeedback,
      hintFeedback: hintFeedback,
      correctFeedback: correctFeedback
    };
  }
  

  


const InputComponent = ({text, validCard, inputClassName, hintLvl, speak, setState}) => {

    const [textInput, setTextInput] = useState()
    const [startTime, setStartTime] = useState()
    const [error, setError] = useState(0)
    const time = 3 + 0.33*text?.length
    const [hint, setHint] = useState(0)
    const {workspace} = useStateContext()

    useEffect(() => {
        if (hintLvl == 0) {
            setHint(0)
        }
        if (hintLvl == 1) {
            setHint(1)
        }
        if (hintLvl == 2) {
            setHint(Math.ceil(text.length/2))
        }
        if (hintLvl == 3) {
            setHint(Math.ceil(text.length))
        }
    }, [hintLvl, text])

    useEffect(() => {
        if (hint > 0) {
            const bg = document.querySelector('#bganswer')
            bg.value = ""
        }
    }, [hint])

    useEffect(() => {
        console.log('nouveau !!')
        setTextInput("")
        
        setStartTime(new Date())
    }, [])

    const handleChange = (value) => {
        const hints = document.querySelectorAll('.hint-letter')

        const comparison =  value && value.toLowerCase()?.trim()?.split('').map((letter, index) => {
            return letter === text.toLowerCase().trim()?.[index] ? 1 : letter == normalize(text?.toLowerCase().trim()?.[index]) ? 0.5 : 0;
        });

        hints.forEach((hint, index) => {
            if (comparison?.[index] === 1) {
                hint.style.backgroundColor = '#18b5be';
                hint.style.transform = 'translateY(2px)';

            } else if (comparison?.[index] === 0.5) {
                hint.style.backgroundColor = '#f08567';
                hint.style.transform = 'translateY(2px)';
            }
             else {
                hint.style.transform = 'translateY(0px)';
                hint.style.backgroundColor = ''; // Réinitialiser la couleur si la lettre est incorrecte
            }
        });

        console.log('comparison', comparison)
        if (value?.trim().length != text?.trim().length) return
        const diffInMs = new Date() - startTime  
        const bonusTest = diffInMs/1000 < time && hintLvl == 0
        console.log('diffInMs', diffInMs)
        
        console.log('textInput', textInput)
        console.log('text', text)
        // const goodWithoutAccent = value !== answer && comparerSansAccents(value || "", answer || "");
        if (value.toLowerCase() == text.toLowerCase() || comparerSansAccents(value || "", text || "")) {
            let xpBonus = bonusTest ? 2 : 1
            if (hintLvl == 3) xpBonus = 0
            
            const bg = document.querySelector('#bganswer')
            bg.classList.add('text-blink');
            const xpRef = document.querySelector('#bonusInput')

            if (xpBonus) {
                xpRef.innerHTML = `<div class="absolute flex gap-1 items-center pointer-events-none add-xp">+${xpBonus} <img src="/images/icons/coin-xp.png" class="h-6" /></div>`
            }
            mp3_valid.play()

            speak && speak()

            if (setState) {
                // mode révision
                setTimeout(() => {
                    bg.classList.remove('wizz');
                    console.log('__blink c validé avec state' )
                    validCard(xpBonus)
                    !bonusTest && setState(1)
                    
                  }, 500);

            } else {
                 // mode classique
                console.log('__blink c validé sans state' )
                setTimeout(() => {
                    validCard({valid: true})
                }, [300])
            }
            
            
 
        
        } else if (value?.trim().length == text?.trim().length){ 
            console.log('FAUXXX')
            
            mp3_bad.play()
            const bg = document.querySelector('#bganswer')
            bg.classList.add('wizz');
            setTimeout(() => {
                bg.classList.remove('wizz');
              }, 300); // Durée de l'animation

        }
    
    }
      

    // faire animation du +1 au clique 

    let fontSize = "text-xl" 
    let ratio = 1
    if (text.length > 16) {
        fontSize = "text-base"
        ratio = 1.25
    }
    if (text.length > 20) {
        fontSize = "text-md"
        ratio = 1.3
    }
    if (text.length > 26) {
        fontSize = "text-sm"
        ratio = 1.3
    }
    if (text.length > 30) {
        fontSize = "text-xs"
        ratio = 1.4
    }

    if (text.length < 10) {
        fontSize = "text-2xl"
        ratio = 0.85
    }

    return <div className="inline-block relative ">
        <div id={"bonusInput"} onClick={(e) => {e.preventDefault()}} className={`absolute pointer-events-none -top-[14px] text-cyan-500 h-[31px] -right-1 rounded-md  z-10`} style={{pointerEvents: "none" }}></div>

        <div onClick={(e) => {e.preventDefault()}} className={`absolute -top-[3px] text-cyan-500 h-[31px] -right-2 rounded-md  z-10`} style={{ animation: `ciao 0.4s ease-in forwards ${time- 0.2}s`, pointerEvents: "none" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path fillRule="evenodd" d="M5 4a.75.75 0 0 1 .738.616l.252 1.388A1.25 1.25 0 0 0 6.996 7.01l1.388.252a.75.75 0 0 1 0 1.476l-1.388.252A1.25 1.25 0 0 0 5.99 9.996l-.252 1.388a.75.75 0 0 1-1.476 0L4.01 9.996A1.25 1.25 0 0 0 3.004 8.99l-1.388-.252a.75.75 0 0 1 0-1.476l1.388-.252A1.25 1.25 0 0 0 4.01 6.004l.252-1.388A.75.75 0 0 1 5 4ZM12 1a.75.75 0 0 1 .721.544l.195.682c.118.415.443.74.858.858l.682.195a.75.75 0 0 1 0 1.442l-.682.195a1.25 1.25 0 0 0-.858.858l-.195.682a.75.75 0 0 1-1.442 0l-.195-.682a1.25 1.25 0 0 0-.858-.858l-.682-.195a.75.75 0 0 1 0-1.442l.682-.195a1.25 1.25 0 0 0 .858-.858l.195-.682A.75.75 0 0 1 12 1ZM10 11a.75.75 0 0 1 .728.568.968.968 0 0 0 .704.704.75.75 0 0 1 0 1.456.968.968 0 0 0-.704.704.75.75 0 0 1-1.456 0 .968.968 0 0 0-.704-.704.75.75 0 0 1 0-1.456.968.968 0 0 0 .704-.704A.75.75 0 0 1 10 11Z" clipRule="evenodd" />
            </svg>
        </div>
        <div onClick={() => {
        console.log('click input')
       
    }} key={"container_"+text} className={`relative my-1 mb-[-4px] overflow-hidden h-[36px] ${fontSize} rounded-md   mx-1 font-mono inline-block`}>
        
        

        <div className='absolute -bottom-[0px] pointer-events-none	 h-[36px]  justify-end flex w-full grow z-[1]'><div onClick={(e) => {e.preventDefault()}} className={` flex   h-[30px] rounded bg-purple-500/10 pointer-events-none	  z-10`} style={{ width: '0%', animation: `unfillBar ${time}s ease-in forwards`, pointerEvents: "none" }}></div></div>




        <input 
            id="bganswer"
            autoFocus={true}
            className={`font-mono ${workspace.lang == "ar-XA" ? "text-right" : ""} text-indigo-500 bg-[#ffeac6] rounded bg-transparent  px-[6px] outline-none !tracking-widest focus:outline-none ${inputClassName}`}
            key={text}
            type="text"
            autoComplete="off" 
            autoCorrect="off"
            // placeholder={text[0] + " ".repeat(text.length - 1)}
            onChange={(e) => {handleChange(e.target.value)}}
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            />
            
            {/* <input 
            className="font-mono pointer-events-none rounded  bg-transparent px-[6px] z-10 outline-none text-indigo-500 !tracking-widest	focus:outline-none absolute left-0 right-0 top-[4px] tracking-wide"
            key={"shadow_2"+text}
            type="text"
            value={"_".repeat(text.length) }
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            /> */}
            <div className={`duration-200 absolute bottom-0 left-0 right-0 min-h-[4px] h-[4px] max-h-[4px] overflow-hidden flex ${workspace.lang == "ar-XA" ? "flex-row-reverse" : ""} gap-[1px] transition-transform bg-indigo-500/1-0 rounded-xl px-[5px]`} style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}>
                {text.split('')?.map((e,i) => <div id={"l"+i} className={`h-[2px] hint-letter ${e == " " && "opacity-[0.3]"} rounded-xl bg-indigo-500 grow`}></div>)}
            </div>
            <input 
            className={`font-mono ${workspace.lang == "ar-XA" ? "text-right" : ""} pointer-events-none rounded  bg-transparent px-[6px] z-10 outline-none text-pink-500/50 !tracking-widest	focus:outline-none absolute left-0 right-0 top-[0px] tracking-wide`}
            key={"hint_2"+text}
            type="text"
            value={text.slice(0, hint) + " ".repeat(text.length - (1 - hint))}
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            />

</div></div>
}


const TextAreaComponent = ({sentence, setState, speak, validCard, fillWord, help = true}) => {


    const {text_50k} = useStateContext()
    const [scores, setScores] = useState()
    const [hint, setHint] = useState(0)

    useEffect(() => {
        setHint(0)
        setScores()
        handleChange("")
        const bg = document?.querySelector('#bganswer')
        bg?.classList.remove('text-blink')
        const inputTA = document.querySelector('#sentenceTA')

        inputTA.value = ""
        
    }, [sentence])


    const handleChange = (value) => {
        const hints = document.querySelectorAll('.hint-letter')
        const text = sentence.trim()
        const hintLvl = hint || 0

        const comparison =  value && value.toLowerCase()?.split('').map((letter, index) => {
            return letter === text.toLowerCase()?.[index] ? 1 : letter == normalize(text.toLowerCase()?.[index]) ? 0.5 : 0;
        });

        hints.forEach((hint, index) => {
            if (comparison?.[index] === 1) {
                hint.style.color = '#18b5be';
                hint.style.transform = 'translateY(2px)';

            } else if (comparison?.[index] === 0.5) {
                hint.style.color = '#f08567';
                hint.style.transform = 'translateY(2px)';
            }
             else {
                hint.style.transform = 'translateY(0px)';
                hint.style.color = ''; // Réinitialiser la couleur si la lettre est incorrecte
            }
        });

        console.log('comparison', comparison)
        if (value?.trim().length != text?.trim().length) return

        if (value.toLowerCase() == text.toLowerCase() || comparerSansAccents(value.trim() || "", text.trim() || "")) {
            let xpBonus = 1
            let bonusTest = 0
            if (hintLvl == 3) xpBonus = 0
            if (hintLvl == 2) xpBonus = 0

            if (hintLvl == 0) {xpBonus = 2; bonusTest = 1} // ajouter si plus de 1h entre la dernier révision avec lastupdate
            
            const bg = document.querySelector('#bganswer')
            bg.classList.add('text-blink');
            const xpRef = document.querySelector('#bonusInput')

            if (xpBonus && xpRef) {
                xpRef.innerHTML = `<div class="absolute flex gap-1 items-center pointer-events-none add-xp">+${xpBonus} <img src="/images/icons/coin-xp.png" class="h-6" /></div>`
            }
            mp3_valid.play()

            speak && speak()

            if (setState) {
                // mode révision
                setTimeout(() => {
                    bg.classList.remove('wizz');
                    console.log('__blink c validé avec state + hint : ' + hintLvl, xpBonus )
                    validCard(xpBonus, {v_sentence: true})
                    !bonusTest && setState(1)
                    
                  }, 500);

            } else {
                 // mode classique
                console.log('__blink c validé sans state' )
                setTimeout(() => {
                    validCard({valid: true})
                }, [300])
            }
            
            
 
        
        } else if (value?.trim().length == text?.trim().length){ 
            console.log('FAUXXX', value.trim())
            console.log('juste', text.trim())
            
            mp3_bad.play()
            const bg = document.querySelector('#bganswer')
            bg.classList.add('wizz');
            setTimeout(() => {
                bg.classList.remove('wizz');
              }, 300); // Durée de l'animation

        }
     
    

    
    }
    
    

    return <>
        <div className="text-xl flex justify-center text-purple-500 pb-3 text-center font-bold">
            {/* <div className="w-full mb-1"><div className="h-2 bg-purple-500 transition-all  rounded-xl" style={{width: `${Math.round(scores?.score) || 0}%`}}></div></div> */}
            {centToStars(scores?.score/10 || 0.1, 5, "#6366f1", null)}
        </div>

    <div id="bganswer" className="group bg-purple-500/10 text-purple-500 rounded-md relative tracking-widest font-mono">
    {/* <div className="group-hover:opacity-[0%] text-center mt-4 text-purple-500/50 fredoka opacity-[100%] absolute top-0 left-0 right-0 text-left p-2 text-green-500/50 pointer-events-none">Imagine la phrase dans ta tête</div> */}
    <textarea onClick={(e) => setHint(e => e)} id="sentenceTA" onChange={(e) => {
        handleChange(e.target.value)
        const result = scorePhrase(e.target.value, sentence.trim())
        setScores(result)
         
        }} name="" className="w-full absolute top-0 left-0 right-0 bottom-0 h-full p-2 tracking-widest font-mono rounded-md bg-transparent"></textarea>
    {hint >= 0 && <div className="/group-hover:opacity-[100%] /opacity-[0%] absolute top-0 left-0 right-0 text-left p-2 text-purple-500/50 pointer-events-none">{sentence?.split(' ')?.map(word => ({word: word, hint: word == fillWord ? 0 : hint > 1 ? word?.length > 2 ? 1 : 0 : 0,rank: text_50k?.indexOf(transformText(word))})).map(e => <span className={`${clean(e.word) == clean(fillWord) ? "text-transparent" : (e.rank > 2000 || e.rank == -1) ? "text-gray-500/80" : ""}`}>
        {(e.rank < 2000 && e.rank != -1 && e.word != fillWord) || !help ? <>{e.word.slice(0, e.hint)}<span className="text-transparent">{"_".repeat(e.word.length - e.hint)} </span></> : e.word + " "}
         </span>)}
        </div>}
    {help && <div className="/group-hover:opacity-[100%] /opacity-[0%] absolute top-[2px] left-0 right-0 text-left p-2 text-purple-500/30 pointer-events-none">
        {sentence?.split('')?.map((e,i) =>  <span id={"lt"+i} className={`hint-letter ${(e == " " || e == ",") ? "" : hint == 0 ? "text-transparent" : ""}`}>{(e == " " || e == ",") ? e : <span>_</span>}</span> )}
        {/* {sentence?.split('')?.map((e,i) => (e == " " || e == ",") ? e : <span id={"lt"+i} className={`h-[2px] inline-text hint-letter ${e == " " && "opacity-[0.3]"} rounded-xl bg-indigo-500 inline-block mt-[18px]`}>_</span>)} */}


        </div>}
        <div className="p-2 text-left text-gray-300/10  ">{sentence}</div>
        <div className="absolute -bottom-8 left-2" onClick={() => {setHint(e => e+1)}}>{hint < 3 && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={"h-4"}>
  <path d="M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z" />
</svg>}
</div>
        <div className="absolute bottom-1 right-2"><AudioRecorder setTextAudio={(e) => {
            const sentenceTA = document.querySelector('#sentenceTA')
            if (sentenceTA) sentenceTA.value += e
            handleChange(sentenceTA?.value)
            const result = scorePhrase(sentenceTA?.value, sentence.trim())
            setScores(result)
         
            }} />
            </div>
           

           
   </div>
   {/* <div className="flex gap-2 justify-end"><div className="bg-purple-100 text-purple-500 border-2 border-purple-600 border-b-4  px-4 mt-4 rounded-xl" onClick={() => {
   const sentenceTA = document.querySelector('#sentenceTA')
   const result = scorePhrase(sentenceTA?.value, sentence.trim())
   setScores(result)

   console.log('result', result)
   }}>Valider</div></div> */}
  
   <div onClick={() => {
             

                // scorePhrase("Je sui arriver la maison", "Je suis arrivé à la maison");
                // scorePhrase("Je arriver suis à la maison", "Je suis arrivé à la maison");
                // scorePhrase("Je suis la maison", "Je suis arrivé à la maison");
          }}>
            {hint > 0 && <div className="p-2 rounded bg-indigo-500/10 text-xs text-left mt-4">
              
            
       
            <div>{hint == 1 && <div  className=" rich-feedback text-lg"  dangerouslySetInnerHTML={{ __html: transformFeedback(scores?.hintFeedback) }}/>}</div>
            {/* <div>hint niv.2 : {<div  className="hover:blur-[0px] hover:grayscale-[0%] grayscale-[100%] transition-all blur-[6px] rich-feedback text-lg"  dangerouslySetInnerHTML={{ __html: transformFeedback(scores?.richFeedback) }}/>}</div> */}
            <div>{hint >= 2 && <div  className="hover:blur-[0px] hover:grayscale-[0%] -grayscale-[100%] transition-all -blur-[6px] rich-feedback text-lg"  dangerouslySetInnerHTML={{ __html: transformFeedback(scores?.correctFeedback) }}/>}</div>
            </div>}
            
            </div></>
}

//  <div>feedback : {scores?.richFeedback}</div> afficher le feedback avec tailwind, baré en rouge si fautes (<b></b>) corrrection en vert (Je suis <b>la/arrivé</b> à la maison) mauvais placement en jaune 


const AudioRecorder = ({ setAudio, setTextAudio }) => {
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [transcript, setTranscript] = useState()

    const {workspace} = useStateContext()

    useEffect(() => {
        // Vérifie si l'API de reconnaissance vocale est disponible
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert("La reconnaissance vocale n'est pas supportée par votre navigateur.");
            return;
        }

        // Initialisation de la reconnaissance vocale
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = false; // Arrête la reconnaissance après une phrase
        recognitionInstance.interimResults = false; // Ne retourne que les résultats finaux
        recognitionInstance.lang = workspace.lang // Langue française

        recognitionInstance.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            console.log('transcript', transcript)
            setTranscript(transcript)
            setTextAudio(transcript); // Envoie le texte reconnu à setTextAudio
        };

        recognitionInstance.onend = () => {
            setIsListening(false); // Arrête l'écoute
        };

        setRecognition(recognitionInstance);
    }, [setTextAudio]);

    const startListening = () => {
        if (recognition) {
            recognition.start();
            setIsListening(true);
        }
    };

    const stopListening = () => {
        if (recognition) {
            recognition.stop();
            setIsListening(false);
        }
    };

    return (
        <div className="">
            <button onClick={isListening ? stopListening : startListening}>
                {isListening ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4">
  <path d="M5.25 3A2.25 2.25 0 0 0 3 5.25v9.5A2.25 2.25 0 0 0 5.25 17h9.5A2.25 2.25 0 0 0 17 14.75v-9.5A2.25 2.25 0 0 0 14.75 3h-9.5Z" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4">
  <path d="M7 4a3 3 0 0 1 6 0v6a3 3 0 1 1-6 0V4Z" />
  <path d="M5.5 9.643a.75.75 0 0 0-1.5 0V10c0 3.06 2.29 5.585 5.25 5.954V17.5h-1.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-1.5v-1.546A6.001 6.001 0 0 0 16 10v-.357a.75.75 0 0 0-1.5 0V10a4.5 4.5 0 0 1-9 0v-.357Z" />
</svg>}

            </button>
        </div>
    );
};

const FillSentence = ({brackedSentence, profileCard, validCard, inputClassName, speak, setState, isNew, setDifficulty}) => {

    const parts = brackedSentence?.split(/\[(.*?)\]/);
    const sentence = brackedSentence?.replace(/[\[\]]/g, '')?.replace(/[\.]/g, '');
    const text = getTextBetweenBrackets(brackedSentence)
    const {workspace, text_50k} = useStateContext()

    const [hintLvl, setHintLvl] = useState(0)

    const nbWord = 9

    useEffect(() => {
        setHintLvl(0)
        // on recommande l'exercice de phrase uniquement pour les petite phrases
        // setMode(profileCard?.lvl >= 3 && sentence.split(' ').length < nbWord ? "sentence" : "word")
    }, [brackedSentence])

    let pinyinSentence = null
    if (workspace.name == "Chinois") {
        const pinyin = hanziToPinyin(text, {
            toneType: 'symbol',
            type: 'array',
            compact: true
        })

        pinyinSentence = hanziToPinyin(brackedSentence, {
            toneType: 'symbol',
            type: 'array',
            compact: true
        }).replace(pinyin, "_".repeat(pinyin.length))?.replace(/[\[\]]/g, '')
    }

    const [mode, setMode] = useState("word")
    const modes = [{name: "Mot", id: "word"}, {name: "Phrase", id: "sentence"}]

       
    return (<>
        <div className="flex gap-2 mb-2 mt-1 text-xs justify-center">{modes?.map(e => <div className={`${e.id == mode ? "bg-purple-500/10" : "opacity-[80%]"} px-2 rounded-xl`} onClick={() => setMode(e.id)}>{e.name}</div>)}</div>
        {mode == "sentence" ? <TextAreaComponent fillWord={parts?.[1]} sentence={sentence.trim()} setState={setState} speak={speak}  validCard={validCard}  /> : 
        <div className="text-base text-indigo-800/80">
            {parts?.map((part, index) => {
                // Si l'index est impair, c'est le contenu entre crochets
                // donc on le remplace par un input
                if (index % 2 === 1) {
                    return <InputComponent setState={setState} speak={speak} hintLvl={hintLvl} inputClassName={inputClassName} key={part} validCard={validCard} size={2}  
                    text={part} 
                 
                    />;
                }
                // Sinon on affiche le texte tel quel  
                return <span key={index} className="">
                {part?.split(' ')?.reduce((acc, e, i) => {
                  if (i % 3 === 0) acc.push([]); // Crée un nouveau groupe tous les 3 mots
                  acc[acc.length - 1].push(e);
                  return acc;
                }, []).map((group, groupIndex) => (
                  <span key={groupIndex} className="inline-block h-max-[10px] bg-purple-300/50 group hover:bg-white rounded-md p-1 inline-block">
                    <span className="flex  gap-[5px]">{
                    group.map((e,i) => (text_50k?.indexOf(transformText(e)) > 3000 ||  text_50k?.indexOf(transformText(e)) == -1 ) ? ( // Vérifie si le caractère n'est pas une lettre
        <span className="h-[20px]" key={i}>{e}</span> // Affiche le caractère sans style spécial
      ) : 
    //   <span className="bg-purple-600 h-[20px]  group-hover:bg-indigo-300/10 text-transparent group-hover:text-indigo-500 rounded">{e}</span>
      <span className="text-purple-600 h-[20px]" key={i}>{e}</span> 
      )}
      </span>
                  </span>
                ))}
              </span>;
                // return <span className="bg-indigo-800 hover:bg-white" key={index}>{part}</span>;
            })}
        </div>}
      
        {pinyinSentence && <div className="text-sm font-sans opacity-50">{pinyinSentence}</div>}





        <div className={`${!isNew ? "bg-purple-300/50 pattern-wiggle" : "pattern-triangle rounded-b-xl bg-[#ffeac6]"}  absolute  flex gap-4 items-center justify-center bottom-0 left-0 right-0 h-[100px]`}>
            
        <div className={`${isNew ? "text-amber-600/90" : "text-purple-500/80"} `} onClick={() => {
                   
                   if (setDifficulty) return setDifficulty(3)
                   if (isNew) {
                       validCard({valid: true})
                   } else {
                       
                       validCard(0)
                       setState(1)
                   }
                   }} >Je ne sais plus </div>
            {hintLvl < 2 && <div onClick={() => {
                setHintLvl( prev => prev + 1 > 2 ?  3 : prev + 1 )
            }} className={`${!isNew ? "text-purple-500/70 border-purple-500/50": "text-amber-600 border-amber-600/70"} px-3 bg-white rounded-xl  border-2 flex gap-1 border-b-4  py-1`}> {hintLvl == 2 ? "Voir la réponse":  hintLvl == 1 ? "Plus d'indice" : "Voir un indice"}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                </svg>
                </div>}
               
        </div>
        </>
        
    );
};

export {FillSentence, TextAreaComponent};