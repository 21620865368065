import { useEffect, useState } from "react";
import { useStateContext } from "../StateProvider";
import { badSound } from "../../V2/components/learn/learnContainer";
import { gpt, soundValid } from "../../utilities/helper/text";
import { supabase } from "../../V2/screens/testScreen";

// ---------- SUPABASE FUNCTIONS ----------

const getQuizzesWithQuestions = async () => {
  const { data: quizzes, error } = await supabase
    .from("quiz")
    .select(`
      *,
      questions (*)
    `);
  if (error) throw error;
  return quizzes;
};

const updateQuizQuestions = async (quizId, questions) => {
  const { error: deleteError } = await supabase
    .from("questions")
    .delete()
    .eq("quiz_id", quizId);
  if (deleteError) throw deleteError;

  const newQuestions = questions.map(({ id, ...rest }) => ({
    ...rest,
    quiz_id: quizId,
  }));
  const { error: insertError, data: insertedQuestions } = await supabase
    .from("questions")
    .insert(newQuestions)
    .select();
  if (insertError) throw insertError;
  return insertedQuestions;
};

const createQuiz = async (name, questions) => {
  const { data: quizData, error } = await supabase
    .from("quiz")
    .insert([{ name }])
    .select();
  if (error) throw error;
  const quizId = quizData[0].id;
  const newQuestions = questions.map(({ id, ...rest }) => ({
    ...rest,
    quiz_id: quizId,
  }));
  const { error: questionError, data: insertedQuestions } = await supabase
    .from("questions")
    .insert(newQuestions)
    .select();
  if (questionError) throw questionError;
  return { ...quizData[0], questions: insertedQuestions };
};

const updateQuizName = async (quizId, name) => {
  const { error } = await supabase
    .from("quiz")
    .update({ name })
    .eq("id", quizId);
  if (error) throw error;
};

// ---------- IA GENERATOR COMPONENT (ChatGPT) ----------

const QuizAIGenerator = ({ onGenerate, quizInfo }) => {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);



  const handleGenerate = async () => {
    if (!prompt) return;
    setLoading(true);
    try {

      console.log('quizInfo', quizInfo)
      return
      const alreadyAsked = `Questions that have already been asked : 

      `;

      const text_request = `
      You are tasked with creating a quiz on a given topic. Your goal is to generate a set of multiple-choice questions, each with four possible answers, and indicate the correct answer.

      The topic for the quiz is:
      <prompt>
      ${prompt}
      </prompt>
      
      To create the quiz, follow these steps:
      
      1. Generate 10 multiple-choice questions related to the given topic.
      2. For each question, create four possible answers, ensuring that one of them is correct.
      3. Verify that the correct answer you've chosen is indeed accurate.
      4. Format your output as a JSON object with the following structure:
      
      {
        "quiz": [
          {
            "print": "Question text goes here",
            "props": "Answer 1, Answer 2, Answer 3, Answer 4",
            "correct": "Correct answer goes here"
          },
          // Repeat this structure for all 5 questions
        ]
      }

      
      
      Important notes:
      - Ensure that the "correct" field exactly matches one of the answers in the "props" field.
      - Do not use any markdown formatting in your output.
      - Provide only the JSON object as your response, without any additional text or explanations.
      - Double-check that all your answers are factually correct before including them in the output.
      
      Begin generating the quiz now, following the instructions and format provided above.`;


      const request = [{ role: "user", content: text_request }];
      console.log("request", request);
      const requestData = await gpt(request, true, null, {
        model: "gpt-4o",
        format: "json_object",
      });
      console.log("requestData", requestData);
      const data = JSON.parse(requestData.choices[0]?.message?.content);
      const questions = data?.quiz?.map((e) => ({
        ...e,
        quiz_id: quizInfo?.id || null,
      }));
      console.log("questions", questions);
      if (data?.quiz && questions) {
        onGenerate(questions);
      }
    } catch (error) {
      console.error("Erreur lors de la génération du quiz par ChatGPT :", error);
    }
    setLoading(false);
  };

  return (
    <div className="mb-6 p-4 border-2 border-dashed rounded bg-gradient-to-r from-yellow-100 to-yellow-200 shadow-inner">
      <h2 className="text-lg font-bold mb-2 text-yellow-800">
        Générer un quiz par ChatGPT
      </h2>
      <textarea
        placeholder="Ex: Quiz de culture générale sur la France"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-yellow-400"
      />
      <button
        onClick={handleGenerate}
        disabled={loading || !prompt}
        className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition"
      >
        {loading ? "Génération en cours..." : "Générer Quiz"}
      </button>
    </div>
  );
};

// ---------- QUIZ EDITOR ----------

const QuizEditor = ({ initialQuestions, quizInfo, onSave, onGenerateQuiz }) => {
  const [quiz, setQuiz] = useState(initialQuestions);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  // On met à jour l'état local dès que les questions initiales changent
  useEffect(() => {
    setQuiz(initialQuestions);
  }, [initialQuestions]);

  // Auto-save on blur
  const handleBlur = () => {
    if (isValidQuestion()) {
      onSave(quiz);
    }
  };

  const handleAddQuestion = () => {
    const newQuiz = [
      ...quiz,
      {
        print: "Nouvelle question",
        props: "Réponse 1, Réponse 2, Réponse 3, Réponse 4",
        correct: "Réponse 1",
      },
    ];
    setQuiz(newQuiz);
    setCurrentQuestion(newQuiz.length - 1);
    // Sauvegarde immédiate si la nouvelle question est valide
    if (
      newQuiz.every((q) => q.print && q.props.split(", ").length >= 2 && q.props.includes(q.correct))
    ) {
      onSave(newQuiz);
    }
  };

  const handleDeleteQuestion = (index) => {
    const newQuiz = quiz.filter((_, i) => i !== index);
    setQuiz(newQuiz);
    setCurrentQuestion(Math.min(currentQuestion, newQuiz.length - 1));
    // Sauvegarde après suppression si valide
    if (
      newQuiz.length > 0 &&
      newQuiz.every((q) => q.print && q.props.split(", ").length >= 2 && q.props.includes(q.correct))
    ) {
      onSave(newQuiz);
    }
  };

  const handleQuestionChange = (field, value) => {
    const newQuiz = [...quiz];
    newQuiz[currentQuestion] = { ...newQuiz[currentQuestion], [field]: value };
    setQuiz(newQuiz);
  };

  const isValidQuestion = () => {
    const q = quiz[currentQuestion];
    return q && q.print && q.props.split(", ").length >= 2 && q.props.includes(q.correct);
  };

  const handleAIGeneratedQuiz = (generatedQuestions) => {
    if (generatedQuestions && generatedQuestions.length > 0) {
      console.log("generatedQuestions", generatedQuestions);
      setQuiz(generatedQuestions);
      setCurrentQuestion(0);
      if (onGenerateQuiz) {
        onGenerateQuiz(generatedQuestions);
      }
    }
  };


  if (!quizInfo?.id) return <div className="flex justify-center"><div className="px-4 bg-purple-500 text-white rounded-xl p-2" onClick={() => onSave(quiz)}> Ajouter </div></div>

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-xl overflow-y-auto max-h-[calc(100vh-200px)]">
      {/* Section IA */}
      <QuizAIGenerator quizInfo={quizInfo} onGenerate={handleAIGeneratedQuiz} />

      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-indigo-800">Éditeur de Quiz</h1>
        <button
          onClick={handleAddQuestion}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
        >
          + Ajouter Question
        </button>
      </div>

      <div className="flex flex-col md:flex-row gap-4 mb-6">
        {/* Liste des questions */}
        <div className="md:w-1/3 space-y-2">
          {quiz.map((_, index) => (
            <div
              key={index}
              className={`p-3 rounded cursor-pointer ${
                currentQuestion === index ? "bg-indigo-200" : "bg-indigo-50"
              } hover:bg-indigo-100 transition`}
              onClick={() => setCurrentQuestion(index)}
            >
              <span className="font-semibold">Q{index + 1}:</span>{" "}
              {quiz[index].print.substring(0, 30) || "Nouvelle question"}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteQuestion(index);
                }}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                ×
              </button>
            </div>
          ))}
        </div>

        {/* Éditeur de question */}
        <div className="md:w-2/3 bg-white p-4 rounded-lg shadow border border-indigo-200">
          {quiz.length > 0 ? (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Question :</label>
                <input
                  type="text"
                  value={quiz[currentQuestion].print}
                  onChange={(e) => handleQuestionChange("print", e.target.value)}
                  onBlur={handleBlur}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Réponses (séparées par des virgules) :</label>
                <textarea
                  value={quiz[currentQuestion].props}
                  onChange={(e) => handleQuestionChange("props", e.target.value)}
                  onBlur={handleBlur}
                  className="w-full p-2 border rounded h-24 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Bonne réponse :</label>
                <select
                  value={quiz[currentQuestion].correct}
                  onChange={(e) => handleQuestionChange("correct", e.target.value)}
                  onBlur={handleBlur}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
                >
                  {quiz[currentQuestion].props.split(", ").map((prop, index) => (
                    <option key={index} value={prop.trim()}>
                      {prop.trim()}
                    </option>
                  ))}
                </select>
              </div>
              {!isValidQuestion() && (
                <div className="text-red-500 mb-4">
                  ⚠️ La question doit contenir au moins 2 réponses et la bonne réponse doit être dans la liste.
                </div>
              )}
            </>
          ) : (
            <div className="text-gray-500 text-center py-8">
              Cliquez sur "+ Ajouter Question" pour commencer
            </div>
          )}
        </div>
      </div>
      {!quizInfo?.id && <div onClick={() => onSave(quiz)}>Créer</div>}
      {/* Bouton de sauvegarde retiré pour auto-save via onBlur */}
    </div>
  );
};


// ---------- QUIZ READER ----------

const QuizzReader = ({ quiz }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isAnswered, setIsAnswered] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [showCorrect, setShowCorrect] = useState(false);

  useEffect(() => {
    if (quiz && quiz.length > 0) {
      const answers = quiz[currentQuestion].props.split(", ");
      setShuffledAnswers([...answers].sort(() => Math.random() - 0.5));
    }
  }, [currentQuestion, quiz]);

  const handleAnswer = (answer) => {
    if (!isAnswered) {
      const correct = answer === quiz[currentQuestion].correct;
      setIsAnswered(true);
      setShowCorrect(answer !== quiz[currentQuestion].correct);

      if (correct) {
        setScore((s) => s + 1);
        soundValid?.play();
      } else {
        badSound.play();
      }

      setTimeout(() => {
        setCurrentQuestion((prev) => (prev + 1) % quiz.length);
        setIsAnswered(false);
        setShowCorrect(false);
      }, correct ? 500 : 1500);
    }
  };


  if (!quiz[currentQuestion]) {
    <div>Pas de questions</div>
  }

  return (
    <div className="max-w-3xl mx-auto p-6 rounded-lg bg-white/90 shadow-2xl border-2 border-purple-300">
      <div className="mb-4 text-lg font-bold text-indigo-700">
        {score} / {quiz.length}
      </div>
      <div className="mb-6">
        <h2 className="text-3xl font-semibold text-indigo-800 mb-4">
          {quiz[currentQuestion]?.print}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {shuffledAnswers.map((answer) => (
            <button
              key={answer}
              onClick={() => handleAnswer(answer)}
              className={`p-4 rounded-lg transition-all font-medium ${
                isAnswered
                  ? answer === quiz[currentQuestion].correct
                    ? "bg-green-400 text-white"
                    : showCorrect && answer !== quiz[currentQuestion].correct
                    ? "bg-red-400 text-white"
                    : "bg-gray-200 text-gray-800"
                  : "bg-blue-300 text-white hover:bg-blue-400"
              }`}
              disabled={isAnswered}
            >
              {answer}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

// ---------- CLASSROOM COMPONENT ----------

const ClassRoom = () => {
  const { setActiveScreen } = useStateContext();
  const [quizzes, setQuizzes] = useState([]);
  // selectedQuizIndex: null = no quiz selected, otherwise index in quizzes array
  const [selectedQuizIndex, setSelectedQuizIndex] = useState(null);
  // currentView: "list", "reader" or "editor"
  const [currentView, setCurrentView] = useState("list");
  const [quizName, setQuizName] = useState("");

  console.log('classRoom')

  useEffect(() => {
    setActiveScreen("class");
    const fetchQuizzes = async () => {
      try {
        const data = await getQuizzesWithQuestions();
        setQuizzes(data);
      } catch (error) {
        console.error("Erreur lors du chargement des quiz :", error);
      }
    };
    fetchQuizzes();
  }, [setActiveScreen]);

  // When a quiz is selected, initialize the editable name.
  useEffect(() => {
    if (selectedQuizIndex !== null && quizzes[selectedQuizIndex]) {
      setQuizName(quizzes[selectedQuizIndex].name);
    }
  }, [selectedQuizIndex, quizzes]);

  const handleSaveQuiz = async (updatedQuestions) => {
    try {
      if (selectedQuizIndex === null) {
        // Creating a new quiz
        const newQuiz = await createQuiz(quizName || "Nouveau Quiz", updatedQuestions);
        setQuizzes((prev) => [...prev, newQuiz]);
        setSelectedQuizIndex(quizzes.length);
        // setCurrentView("reader");
      } else {
        // Updating an existing quiz, including its name.
        const quizToUpdate = quizzes[selectedQuizIndex];
        if (quizName !== quizToUpdate.name) {
          await updateQuizName(quizToUpdate.id, quizName);
        }
        const updatedQuestionsFromDB = await updateQuizQuestions(quizToUpdate.id, updatedQuestions);
        const updatedQuiz = { ...quizToUpdate, name: quizName, questions: updatedQuestionsFromDB };
        const updatedQuizzes = [...quizzes];
        updatedQuizzes[selectedQuizIndex] = updatedQuiz;
        setQuizzes(updatedQuizzes);
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du quiz :", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-300 to-blue-300 pb-20">
      {currentView === "list" && (
        <div className="p-8 pt-[100px]">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-4xl font-bold text-purple-900">Mes Quiz</h1>
            <button
              onClick={() => {
                // Pour créer un nouveau quiz, on affiche l'éditeur vide.
                setSelectedQuizIndex(null);
                setQuizName("");
                setCurrentView("editor");
              }}
              className="bg-indigo-500 text-white px-6 py-3 rounded-lg shadow hover:bg-green-700 transition"
            >
              + Nouveau Quiz
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {quizzes.map((quiz, i) => (
              <div
                key={i}
                onClick={() => {
                  setSelectedQuizIndex(i);
                  setCurrentView("reader");
                }}
                className="cursor-pointer bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition transform hover:-translate-y-1 border-t-4 border-indigo-500"
              >
                <h2 className="text-2xl font-semibold text-indigo-800">{quiz.name}</h2>
                <p className="text-gray-600 mt-2">
                  {quiz.questions?.length || 0} questions
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {(currentView === "reader" || currentView === "editor") && (
        <div className="max-h-screen h-screen bg-indigo-400/40 overflow-y-auto">
          {/* Header */}
          <header className="flex items-center justify-between p-4 bg-gradient-to-r from-indigo-700 to-purple-700 text-white shadow-lg sticky top-0 z-10">
            <button
              onClick={() => setCurrentView("list")}
              className="text-2xl font-bold hover:underline"
            >
              ← Retour
            </button>
            {currentView === "editor" ? (
              <input
                type="text"
                value={quizName}
                placeholder="Nom du quiz"
                onChange={(e) => setQuizName(e.target.value)}
                onBlur={(e) => handleSaveQuiz()}
                className="text-2xl font-semibold bg-transparent border-b-2 border-white focus:outline-none focus:border-yellow-300 text-center"
              />
            ) : (
              <h1 className="text-2xl text-white/50 font-semibold">{selectedQuizIndex !== null && quizzes[selectedQuizIndex].name}</h1>
            )}
            {currentView === "reader" ? (
              <button
                onClick={() => setCurrentView("editor")}
                title="Modifier le quiz"
                className="text-2xl hover:underline"
              >
                ✎
              </button>
            ) : (
              <button
                onClick={() => setCurrentView("reader")}
                title="Voir le quiz"
                className="text-2xl hover:underline"
              >
                👁
              </button>
            )}
          </header>
          <main className="p-4">
            {currentView === "reader" && selectedQuizIndex !== null && (
              <QuizzReader quiz={quizzes[selectedQuizIndex].questions} />
            )}
            {currentView === "editor" && (
              <QuizEditor
                quizInfo={selectedQuizIndex !== null ? quizzes[selectedQuizIndex] : {}}
                initialQuestions={selectedQuizIndex !== null ? quizzes[selectedQuizIndex].questions : []}
                onSave={handleSaveQuiz}
              />
            )}
          </main>
        </div>
      )}
    </div>
  );
};

export { ClassRoom };
