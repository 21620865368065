import { doc, updateDoc } from '@firebase/firestore';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { db } from '../../config';
import { gpt, speak } from '../../utilities/helper/text';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { WaveFile } from 'wavefile';
import { dotStream } from 'ldrs'
import { mp3_bad, mp3_valid } from '../helperData';
import { useStateContext } from '../StateProvider';
import { numberSentences, removeEmojis, stopSound } from '../helperFunctions';
import { SuperText } from '../components/SuperText';
import { translateContext, translateFromContext } from './AdminCards';


const Message = ({mess, darkMode, prevMess, classUser, classOther, currentUserChat, selectedScenario, messages, setMessages, isVocal}) => {



    const {workspace} = useStateContext() 
    const [showTrad, setShowTrad] = useState(false)
    const [revealMsg, setRevealMsg] = useState(true)
    const [vocalMode, setVocalMode] = useState(false)
    const [dataSpeak, setDataSpeak] = useState()
    const [isSpeaking, setIsSpeaking] = useState(false)


  
    const [currentMess, setCurrentMess] = useState(mess)
    const [loadingTrad, setLoadingTrad] = useState(false)
    const [loadingReformulation, setloadingReformulation] = useState(false)



  
    useEffect(() => {
      setCurrentMess(mess)
    }, [mess])
  
    useEffect(() => {
  
        setRevealMsg(!isVocal)
        setVocalMode(isVocal)

    }, [isVocal])
  
  
    const handleReformulation = async () => {
  
      console.log('inject msg')
      console.log('mess', mess)
      console.log('messages', messages)
      console.log('prevMess', prevMess)
      setloadingReformulation(true)
      const query = [
        {"role": "user", "content": `Donne uniquement la réponse. 
        Voici le contexte en ${workspace.name} : "${prevMess?.content}"
        Voici un le message du l'utilisateur : "${mess.content}".
        1 - vérifie si le message est bien formulé et sans faute et que tout les mots sont ${workspace.name}.
         Si le message contient des fautes ou des mots qui ne sont pas allemand, corrige les fautes et met à jour le message.
         Si le message contient n'est pas correcte en ${workspace.name} , reformule le.
        Si le message ne contient pas de fautes et est bien formulé en allemand, laisse le tel quel et renvoie le.
      
        `}
        //         // ajoute un [%] en début de phrase pour la justess de la réponse entre 0 et 100% (bonne réponse et bonne formulation)

    ]
      const newText = await gpt(query, true, null)
      console.log('query', query)
      const traduction = await translateFromContext(newText.choices[0]?.message.content, "français")
      const updatedMessages = messages.map(m => m == mess ? {...m, ia_updated: newText.choices[0]?.message.content, trad: traduction} : m)
  
      setMessages(updatedMessages);
      setloadingReformulation(false)
      // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})
  
  
  
  
   
   
    }
  
  
    const handleTrad = async() => {
  
      if (!currentMess.trad) {
        console.log('pas de traduction !')
        const query = [
          {"role": "user", "content": `Donne uniquement la réponse. traduit "${mess.content}" en français`}
      ]
        setLoadingTrad(true)
        const tradData = await gpt(query, true, null)
        setLoadingTrad(false)
        const tradText = tradData.choices[0]?.message.content
        console.log('tradText', tradText)
        setCurrentMess({...currentMess, trad: tradText})
        const updatedMessages = messages.map(m => m == mess ? {...m, trad: tradText } : m)
        setMessages(updatedMessages);
        setShowTrad(true)
        // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})
        
  
  
      } else {
        setShowTrad(t =>!t)
        console.log('trad !!??')
      }
      
    }
  
  
    const maxCharacter = mess.content?.length
  
    // ajoute mess.ug user generated pour ajoute les actions ['reformulation']
  
    return <div onClick={() => {
      console.log('selectedScenario', selectedScenario)
    }} className={`${mess.role == "user" ? "justify-end text-white ml-4" : "mr-4"} transition-all relative flex gap-2`}>
         <div className=''>
        <div className={`flex transition-all relative ${mess.role == "user" ? "justify-end text-white "  : ""}`}><div className={`rounded-xl border-[2px] ${mess.role == "user" ? classUser:classOther } ${mess.role == "user" ? 
        mess.ia_updated ? `text-xs pb-2  
          ${darkMode ? " text-white/80 bg-white/20 border-transparent": "text-blue-500  bg-white/70"}  
          text-right justify-end self-end` : `self-end rounded-tr-[2px] bg-blue-500 border-blue-700 text-right justify-end` : `rounded-tl-[2px] w-full ${vocalMode ? "pr-8" : ""}  ${darkMode ? "bg-[#5e00c9]  text-white border-black/20":" bg-white text-slate-700"}  `} min-w-[40px] max-w-[90vw] px-[8px] py-[6px] overflow-hidden border-b-[4px] mb-3 `}>
        {/* <SuperEditor className="text-xl pb-0" onBlur={() => {console.log('ee')}} content={markdownToHtml(mess.content)?.replaceAll('\n', '<br/> ---')} /> */}
        
          {vocalMode && <div>
            <div className='flex items-center gap-2'>
          {/* <div>{dataSpeak?.["_duration"]}s</div> */}
            <div className='' onClick={async() => {
              if (isSpeaking) {
                stopSound()
                setIsSpeaking(false)
              return
            }
              setIsSpeaking(true)
              const dataSpeak = await speak({text: removeEmojis(mess.content), lang: workspace?.lang, setDataSpeak })
              setIsSpeaking(false)
              console.log(dataSpeak)
            }
              }>
                {isSpeaking ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
  </svg>
  : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                </svg>}
  </div>
  <div className={`h-3 ${isSpeaking ? "animate-pulse" : ""}  w-full bg-white/50 rounded-full`}></div>
  
  
            </div>
            <div className='text-xs text-transparent h-[5px] max-h-[5px] pr-8 '>
              {!revealMsg && mess.content?.slice(0, maxCharacter < 70 ? maxCharacter : 70) + '...'}
              <div className='absolute right-2 top-2 text-white/70' onClick={() => {setRevealMsg(t => !t)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${revealMsg ? "rotate-[180deg]" : ""}`}>
    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
  </svg>
  </div>
              </div>
            </div>}
          {revealMsg && <SuperText 
                          vocalMode={false} 
                          colorText={false}
                          text={numberSentences(mess.content)} 
                          imgContext={selectedScenario?.bg}
                          ct={{}}
                          trad={numberSentences(mess.trad)} 
                          decks={[]} 
                          />}

            {vocalMode && <div onClick={() => {setVocalMode(false); setRevealMsg(true)}}>Voir le texte</div>}
  
            {!vocalMode && <div id="helper" className={`${mess.role == "user" ? "justify-end" : ""} flex mt-2 items-center`}>
              {isSpeaking ? <l-waveform size="18" speed="1.6"   color="#80746B4D" ></l-waveform> : <svg onClick={async() => {
                setIsSpeaking(true)
                await speak({text: removeEmojis(mess.content), lang: workspace?.lang})
                setIsSpeaking(false)
                }}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
              </svg>}
                {mess.ug ? !mess.ia_updated && <div className='px-2 text-xs opacity-[0.7] rounded-xl' onClick={async() => {handleReformulation() }} >Reformuler {loadingReformulation && "..."} </div> : <div onClick={() => handleTrad()} className={`${showTrad ? "bg-amber-100/30" : ""} px-2 text-xs opacity-[0.7] rounded-xl`}>Voir la traduction  {loadingTrad && "..."}</div>}
              </div>}
              {currentMess.trad && showTrad && <div className={`transition-all text-slate-500 ${darkMode ? "bg-black/30- text-white/50-" : "bg-slate-100/60- text-slate-500-"} text-xs italic   p-2 pt-1 mt-2 -mx-2 -mb-2   ${vocalMode ? "!-mr-8  " : ""}`}>{mess.trad}</div>}
  
            </div>
  
          </div>
          {mess.ia_updated && <div className='bg-emerald-500 text-right pl-6 relative rounded-xl border-[2px] border-black/20 border-b-[4px] mb-3 -mt-[20px] p-2 pt-1  -mx-2 -mb-2'>
            <SuperText 
          vocalMode={false} 
          colorText={false}
          imgContext={selectedScenario?.bg}
          ct={{}}
          text={numberSentences(mess.ia_updated)} 
          trad={numberSentences(mess.trad)} 
          className=''>
          <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4 absolute top-1 left-1">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
  </svg>
       
            </SuperText><div className='text-xs italic opacity-[80%]'>{mess.trad}</div></div>}
  
      
   
  
         
  
      
            
             </div>
    </div>}
dotStream.register()

// Default values shown

const key_1 = "3fb405dd620d40eea3c0604929d8089f"

function countNewLines(input) {
    let matches = input?.match(/\n/g);
    return matches ? matches.length : 0;
  }


  function readReaction(text) {
    // Expression régulière pour détecter les emojis en début de chaîne
    const emojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;
    
    // Recherche de l'emoji au début du texte
    const match = text.match(emojiRegex);
    
    if (match) {
        const reaction = match[0];
        // Supprimer l'emoji et les espaces éventuels qui suivent
        const textWithoutReaction = text.slice(reaction.length).trim();
        return { reaction, textWithoutReaction };
    } else {
        // Aucun emoji trouvé en début de chaîne
        return { reaction: null, textWithoutReaction: text };
    }
}




const svg_play = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6">
<path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
</svg>
const svg_play_mini = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3">
<path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
</svg>

const svg_pause = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6">
<path fillRule="evenodd" d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" clipRule="evenodd" />
</svg>

const svg_stop = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6">
<path fillRule="evenodd" d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z" clipRule="evenodd" />
</svg>


const svg_trash = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>


const svg_again = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6">
<path fillRule="evenodd" d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" clipRule="evenodd" />
</svg>

const svg_record = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6">
<path d="M8.25 4.5a3.75 3.75 0 1 1 7.5 0v8.25a3.75 3.75 0 1 1-7.5 0V4.5Z" />
<path d="M6 10.5a.75.75 0 0 1 .75.75v1.5a5.25 5.25 0 1 0 10.5 0v-1.5a.75.75 0 0 1 1.5 0v1.5a6.751 6.751 0 0 1-6 6.709v2.291h3a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1 0-1.5h3v-2.291a6.751 6.751 0 0 1-6-6.709v-1.5A.75.75 0 0 1 6 10.5Z" />
</svg>






const RenderPrononciationText = ({pronunciationScoreData, playSnippet, lang}) => {
  return <><p onClick={() => {console.log('pronunciationScoreData', pronunciationScoreData)}} className={`flex flex-wrap gap-1  items-center  py-2 text-sm`}>{pronunciationScoreData?.Words?.map(w => {
    const hasSyllables = w.Syllables?.[0]?.Syllable
    const score = w.PronunciationAssessment?.AccuracyScore
    return <div className={` ${hasSyllables ? "" : "opacity-[70%]"}`} onClick={() => {
      //  speak({text: w.Word, lang: lang})
      console.log('w', w)
      console.log('w.Offset', w.Offset/10000000)
      console.log('w.Duration', w.Duration/10000000)
      playSnippet(w.Offset/10000000, w.Duration/10000000, () => speak({text: w.Word, lang: lang}))
    }}>{
      (!hasSyllables) ? 
      <div  className={` border-b-2 border-dashed relative rounded rounded-b-none ${score > 99 ? "border-transparent" : score < 60 ? "bg-red-500/10 border-red-500 px-1" : score < 80 ? "bg-orange-500/10 border-orange-500 px-1" : score < 90 ? " px-1 bg-yellow-500/10 border-yellow-500" : "border-transparent"}`}>{w.Word} 
        {score < 80 && <span className='absolute top-[-4px] right-[-5px] text-[8px]'>{<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-[9px]">
            <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
            <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
            </svg>
          }</span>}
    </div>
      : 
      <div className={`border-b-2 border-dashed relative rounded rounded-b-none ${score > 99 ? "border-transparent" : score < 60 ? "bg-red-500/10- border-red-500 px-1" : score < 80 ? "bg-orange-500/10- border-orange-500 px-1" : score < 90 ? " px-1 bg-yellow-500/10- border-yellow-500" : "border-transparent"}`}>
      {w.Syllables?.map(s => <span className={`${!s.Grapheme ?  "purple-500" :  s.PronunciationAssessment?.AccuracyScore > 99 ? "text-green-500" : s.PronunciationAssessment?.AccuracyScore < 60 ? "text-red-500/90": s.PronunciationAssessment?.AccuracyScore < 80 ? "text-orange-500" : s.PronunciationAssessment?.AccuracyScore < 90 ? "text-yellow-500/90" : "text-green-500"}`}>{s.Grapheme }</span>)}
      {score < 80 && <span className='absolute top-[-4px] right-[-5px] text-[8px]'>{<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-[9px]">
            <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
            <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
            </svg>
          }</span>}
      </div>
      } </div>})
      
    
    }</p></>
}

const SpeechToTextSimple3 = ({ referenceText, send, lang }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcription, setTranscription] = useState('');
  const [pronunciationResult, setPronunciationResult] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingAnalyse, setLoadingAnalyse] = useState(false);
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [goAnalyse, setGoAnalyse] = useState() 
  const chunksRef = useRef([]);
  const [pronunciationScoreData, setPronunciationScoreData] = useState(null);


  const init = () => {
    setLoadingAnalyse(false);
    setPronunciationScoreData(null);
    setIsPlaying(false);
    setTranscription('');
    setPronunciationResult()
    setPronunciationScoreData(null);
    setAudioBlob()
  }
  useEffect(() => {
    init()
  }, [referenceText])

  const startRecording = useCallback(() => {
    setTranscription('');
    setPronunciationResult(null);
    setAudioBlob(null);
    chunksRef.current = [];

    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      recorder.onstop = async () => {
        const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
        try {
          const wavBlob = await convertToWav(blob);
          setAudioBlob(wavBlob);
        } catch (error) {
          console.error('Erreur lors de la conversion en WAV:', error);
        }
      };

      recorder.start();
      mediaRecorderRef.current = recorder;
      setIsRecording(true);
    }).catch(console.error);
  }, []);

  useEffect(() => {
    if (goAnalyse && audioBlob) {
      console.log('goAnalyse')
      analyzeRecording()
      setGoAnalyse(false)
    }
  }, [goAnalyse, audioBlob])


  const stopRecording = useCallback((go = false) => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      
      setGoAnalyse(go)
    }
  }, [isRecording]);

  const convertToWav = async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    const wav = new WaveFile();
    const channelData = audioBuffer.getChannelData(0);
    wav.fromScratch(1, audioBuffer.sampleRate, '32f', channelData);
    wav.toBitDepth('16');
    wav.toSampleRate(16000);
    
    return new Blob([wav.toBuffer()], { type: 'audio/wav' });
  };


  

  const analyzeRecording = useCallback(() => {
    if (audioBlob) {
      const reader = new FileReader();
      setLoadingAnalyse(true)
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, 'francecentral');
        speechConfig.speechRecognitionLanguage = lang === "en-GB" ? "en-US" : lang == "cmN-CN" ? "zh-CN": lang || "en-US"
        const audioConfig = sdk.AudioConfig.fromWavFileInput(new Uint8Array(arrayBuffer));

        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

        const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
          referenceText || "",
          sdk.PronunciationAssessmentGradingSystem.HundredMark,
          sdk.PronunciationAssessmentGranularity.Phoneme,
          true
        );
        pronunciationAssessmentConfig.applyTo(recognizer);

        recognizer.recognizeOnceAsync(
          result => {
            if (result.reason === sdk.ResultReason.RecognizedSpeech) {
              setTranscription(result.text);
              
              const pronunciationAssessmentResult = sdk.PronunciationAssessmentResult.fromResult(result);
              setPronunciationResult(pronunciationAssessmentResult);
            //   setPronunciationScoreData(data => ({
            //     ...data,
            //     Words: [...(data.Words || []), ...pronunciationResult.privPronJson.Words]
            // }));
              setLoadingAnalyse(false)
            } else {
              console.error('La parole n\'a pas été reconnue :', result.reason);
              setLoadingAnalyse(false)
            }
            recognizer.close();
          },
          error => {
            console.error('Erreur lors de la reconnaissance vocale :', error);
            setLoadingAnalyse(false)
            recognizer.close();
          }
        );
      };
      reader.readAsArrayBuffer(audioBlob);
     
    } else {
      console.warn('Aucun audio à analyser');
    }
  }, [audioBlob, lang, referenceText]);

  const playRecording = useCallback((start = 0, time = null, speak = () => {}) => {
    if (audioBlob && audioRef.current) {
      const audioUrl = URL.createObjectURL(audioBlob);
      
      audioRef.current.src = audioUrl;
      
      // Assurez-vous que start est un nombre fini et non négatif
      const safeStart = isFinite(start) && start >= 0 ? start : 0;
      
      audioRef.current.onloadedmetadata = () => {
        // Vérifiez que le temps de départ est valide par rapport à la durée de l'audio
        audioRef.current.currentTime = Math.min(safeStart, audioRef.current.duration);
        
        const playPromise = audioRef.current.play();
        
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
              
              if (time !== null && isFinite(time) && time > 0) {
                setTimeout(() => {
                  audioRef.current.pause();
                  setIsPlaying(false);
                  speak();
                }, time * 1000);
              }
            })
            .catch(error => {
              console.error("Erreur lors de la lecture de l'audio:", error);
              setIsPlaying(false);
            });
        }
      };
      
      audioRef.current.onerror = (e) => {
        console.error("Erreur lors du chargement de l'audio:", e);
        setIsPlaying(false);
      };
      
      return () => {
        URL.revokeObjectURL(audioUrl);
      };
    }
  }, [audioBlob]);

  

  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  return (
    <div className='mt-4'>
      {!transcription && <div className='flex items-center justify-center'>
        <div className=''>
        {isRecording ? (
          <div className='flex gap-2 items-center rounded-full p-4 py-2 bg-[#7D4C2C] border-black/20 border-2 border-2 '>
             <button  className='text-white' onClick={() => {stopRecording(true); }}>{svg_stop}</button>
             <div className='text-black/40 pb-[3px]'>
              <l-dot-stream
                  size="50"
                  speed="2" 
                  color="white" 
                ></l-dot-stream></div>
              <button className='text-slate-300' onClick={() => {stopRecording()}}>{svg_trash}</button>
             
             
          </div>
        ) : (
          <div className='flex gap-2 rounded-full p-4 py-2 overflow-hidden bg-[#7D4C2C] text-white game-btn border-black/20 border-2 ' onClick={startRecording}>{audioBlob ? loadingAnalyse ? <l-quantum
            size="35"
            speed="1.75" 
            color="white" 
          ></l-quantum> :  svg_again  : <div className='flex items-center gap-3 px-4 justify-center'>{svg_record} Parler</div>}</div>
        )}</div>
      </div>}
      {/* <div>
        {audioBlob && <button onClick={analyzeRecording} disabled={!audioBlob}>
          {loadingAnalyse ? "..." : " "}
        </button>} 
      </div> */}
      <div>
     
        <audio ref={audioRef} onEnded={handleAudioEnded} style={{ display: 'none' }} />
      </div>
      {transcription && <div className='p-3 border-2 border-amber-700/30 pt-2 rounded-xl bg-white mt-4'>
        <div className='flex justify-between'>
        <div className='flex gap-2'>
          {audioBlob &&  <button className='flex h-[22px] gap-2 bg-slate-300/20  pr-3 rounded-full px-2 text-xs items-center' onClick={playRecording} disabled={!audioBlob || isPlaying}>
            {svg_play_mini} Moi
          </button> }
          <div className='flex h-[22px] gap-2 bg-slate-300/20  pr-3 rounded-full px-2 text-xs items-center'  onClick={() => speak({text: referenceText, lang: lang})}>{svg_play_mini} Original</div>
        </div>
          <div className='gap-2 py-1 px-3 rounded-full font-semibold text-[#F68236] bg-[#FFDCC5] text-sm items-center'>{Math.round(pronunciationResult.pronunciationScore)}%</div>
        </div>
      
        <RenderPrononciationText pronunciationScoreData={pronunciationResult?.privPronJson} playSnippet={playRecording} lang={lang} />
        
      </div>}
      {transcription && <div className='flex mt-3 items-center justify-center gap-3'>
          <div className='text-sm' onClick={() => {stopRecording(); setTranscription(false); setAudioBlob(); setPronunciationResult()}}>Recommencer</div>
          <div onClick={() => send()} className='bg-[#7D4C2C] text-white px-4 py-1 rounded-full text-base'>Valider</div>
         </div>}
    </div>
  );
};



const SpeechToTextSimple2 = ({ referenceText, send, lang}) => {

    const {profile} = useStateContext()

    const [transcription, setTranscription] = useState('');
    const [pronunciationScore, setPronunciationScore] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recognizer, setRecognizer] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);

    const [loadingRecord, setLoadingRecord] = useState(false);
    const [pronunciationScoreData, setPronunciationScoreData] = useState(null)
  
    useEffect(() => {
      if (lang) {
        const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, 'francecentral');
        speechConfig.speechRecognitionLanguage = lang === "en-GB" ? "en-US" : lang;

  
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const newRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
  

        const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
          referenceText || "",
          sdk.PronunciationAssessmentGradingSystem.HundredMark,
          sdk.PronunciationAssessmentGranularity.Phoneme,
          true
        );
        pronunciationAssessmentConfig.applyTo(newRecognizer);
        console.log('Recognizer initialized with lang:', pronunciationAssessmentConfig);
        console.log('speechConfig', speechConfig)
        console.log('lang', lang)
        console.log('referenceText', referenceText);
        
  
        setRecognizer(newRecognizer);
        console.log('Recognizer initialized with lang:', lang);
      }
    }, [lang, referenceText]);
  
    useEffect(() => {
      return () => {
        // Cleanup function to stop the recognizer if the component is unmounted
        if (recognizer) {
          recognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('Recognition stopped');
              //recognizer.close();
            },
            (error) => {
              console.error('Error stopping speech recognition:', error);
            }
          );
        }
      };
    }, [recognizer]);
  
    useEffect(() => {
      let timeoutId;
  
      if (isRecording) {
        timeoutId = setTimeout(() => {
          stopRecognition();
          recognizer?.stopContinuousRecognitionAsync()
        }, 6*1000*10); // Arrête l'enregistrement après 5 secondes d'inactivité
      }
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, [isRecording]);
  
    const startRecognition = () => {
      try {
        setLoadingRecord(true);
        setTranscription('');
        setPronunciationScoreData('')
        setAudioUrl(null);
        console.log('lang', lang)

        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          const newMediaRecorder = new MediaRecorder(stream);
          setMediaRecorder(newMediaRecorder);
          let chunks = [];
          newMediaRecorder.ondataavailable = e => {
            chunks.push(e.data);
          };
          newMediaRecorder.onstop = e => {
            const blob = new Blob(chunks, { type: 'audio/wav' });
            setAudioUrl(URL.createObjectURL(blob));
          };
          newMediaRecorder.start();
        // const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
       
  
        // const newRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
        // speechConfig.speechRecognitionLanguage = lang;
        // pronunciationAssessmentConfig.applyTo(newRecognizer);
  
        // setRecognizer(newRecognizer);
  
        recognizer.startContinuousRecognitionAsync(
          () => {
            console.log('Recognition started', lang);
            setIsRecording(true);
            setLoadingRecord(false);
          },
          (error) => {
            console.error('Error during speech recognition:', error);
            setIsRecording(false);
          }
        );
  
        recognizer.recognized = (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            console.log('result', e.result);
  
            
            setTranscription((prevTranscription) => (prevTranscription || '') + ' ' + e.result.text);
  
            const pronunciationResult = sdk.PronunciationAssessmentResult.fromResult(e.result);
            console.log('pronunciationResult', pronunciationResult);
            console.log('word', pronunciationResult.privPronJson.Words);
           
           
         
  
            // setPronunciationScore(pronunciationResult.pronunciationScore);
            setPronunciationScoreData(data => ({
              ...data,
              Words: [...(data.Words || []), ...pronunciationResult.privPronJson.Words]
          }));
          }
        };})
      } catch (error) {
        console.error('Error during speech recognition:', error);
        //recognizer.close();
      }
    };
  
    const stopRecognition = () => {
      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log('Recognition stopped');
            setIsRecording(false);
            if (mediaRecorder) {
              mediaRecorder.stop();
            }
  
         //   recognizer.close();
            // setRecognizer(null);
          },
          (error) => {
            console.error('Error stopping speech recognition:', error);
          }
        );
      }
    };
  

    const audioRef = useRef(null)
    const playSnippet = (start, time, speak) => {
      const audio = audioRef.current;
      if (audio) {
        audio.currentTime = start; // Convertir 1223 ms en secondes
        audio.play();
  
        // Stop after 200ms (0.2 seconds), since 1423 ms - 1223 ms = 200 ms
        setTimeout(() => {
          audio.pause();
          speak()
        }, time*1000); // 200 ms
      }
    };

    const active = (transcription || isRecording)
    console.log('pronunciationScoreData', pronunciationScoreData)
  
  
    return (
      <div className={`${active && "bg-[#6b08be]"} rounded-xl`}>
        {audioUrl && (
        <audio ref={audioRef} className='hidden' controls src={audioUrl} />
      )}

        <div className='flex justify-center '>
        <div className={`${active ? "absolute h-[40px] w-[40px] right-4 bottom-3 bg-white" : "h-[60px] w-[60px] text-white -ml-6 bg-amber-600"}    flex items-center justify-center  border-2 border-b-4 border-black/30 rounded-full`} onClick={() => {
          if (!isRecording) {
            if(!transcription)  {startRecognition()}
            else {
              send(transcription);
              setTranscription('')
              setPronunciationScoreData('')
              setAudioUrl(null);
            }
          } else {
            stopRecognition()
          }
        }}>
           
        {!isRecording && !loadingRecord ? transcription ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="text-blue-500 rounded-full  h-8">
    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
  </svg>
   : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` p-2 brounded-full h-10`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
        </svg> : 
         <div className={`${loadingRecord ? " h-10 w-10" : "bg-white text-purple-500 h-10 w-10"} p-2 rounded-full flex items-center justify-center  `}> 
         {loadingRecord ? 
  <l-momentum
    size="40"
    speed="4.3" 
    color="white" 
  ></l-momentum> : 
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
  </svg>
   }</div>
          }
        </div>
        </div>
        
        {!profile.admin ? <p className={`text-xs ${active ? "min-h-[50px] py-2" : ""} italic  flex items-center w-full text-white/90 pr-[60px]  px-10 `}>{
        isRecording ? "Enregistrement en cours ..."  : transcription}
        </p> : 
        <p className={`${active ? "min-h-[50px] py-2" : ""} flex flex-wrap gap-1 pr-[60px] items-center px-10 py-2 text-sm`}>{ isRecording && !transcription ? "Enregistrement en cours ..."  : pronunciationScoreData?.Words?.map(w => {
        const hasSyllables = w.Syllables?.[0]?.Syllable
        const score = w.PronunciationAssessment?.AccuracyScore
        return <div className={` ${hasSyllables ? "" : "opacity-[70%]"}`} onClick={() => {
          //  speak({text: w.Word, lang: lang})
          console.log('w', w)
          console.log('w.Offset', w.Offset/10000000)
          console.log('w.Duration', w.Duration/10000000)
          playSnippet(w.Offset/10000000, w.Duration/10000000, () => speak({text: w.Word, lang: lang}))
        }}>{
          !hasSyllables ? 
          <div  className={`px-1 rounded ${score > 99 ? "" : score < 60 ? "bg-red-500" : score < 80 ? "bg-orange-500" : score < 90 ? "bg-yellow-500" : ""}`}>{w.Word} ({score}) </div>
          : 
          w.Syllables?.map(s => <span className={`${!s.Grapheme ?  "purple-100" :  s.PronunciationAssessment?.AccuracyScore > 99 ? "text-green-100" : s.PronunciationAssessment?.AccuracyScore < 80 ? "text-yellow-200" : "text-red-300/90"}`}>{s.Grapheme }</span>)} </div>})
        
        }</p>}
        {/* {isRecording ? <div className='flex justify-center h-2'><l-waveform
    size="12"
    stroke="3.5"
    speed="1" 
    color="white" 
  ></l-waveform></div> :<div className={`h-2 `}></div>} */}
        {isRecording ? <div className='absolute left-5 bottom-6 h-5'><l-waveform
    size="18"
    speed="1.6" 
    color="white" 
  ></l-waveform> </div>: <div className='text-center' onClick={() => {
          stopRecognition()
          setTranscription()
          setPronunciationScoreData()
          }}>
  
  
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${!transcription && "opacity-0"} absolute left-4 bottom-6 h-5`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
  </svg>
  
  </div>}
     
      </div>
    );
  };

function mergeContentWithTrad(content, trad) {
    /**
     * Fonction récursive pour fusionner les objets.
     *
     * @param {Object} obj1 - L'objet principal.
     * @param {Object} obj2 - L'objet de traduction.
     * @returns {Object} - L'objet fusionné.
     */
    function merge(obj1, obj2) {
      const merged = {};
  
      for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          const val1 = obj1[key];
          const val2 = obj2 ? obj2[key] : undefined;
  
          // Si la valeur est une chaîne de caractères, ajouter la version traduite
          if (typeof val1 === 'string') {
            merged[key] = val1;
            if (typeof val2 === 'string') {
              merged[`${key}_trad`] = val2;
            }
          }
          // Si la valeur est un tableau, traiter chaque élément
          else if (Array.isArray(val1)) {
            if (Array.isArray(val2)) {
              merged[key] = val1.map((item, index) => {
                const tradItem = val2[index];
                if (typeof item === 'object' && item !== null && typeof tradItem === 'object' && tradItem !== null) {
                  return merge(item, tradItem);
                }
                // Si l'élément n'est pas un objet ou la traduction n'existe pas, retourner l'élément original
                return item;
              });
            } else {
              merged[key] = val1;
            }
          }
          // Si la valeur est un objet, fusionner récursivement
          else if (typeof val1 === 'object' && val1 !== null) {
            merged[key] = merge(val1, val2);
          }
          // Pour les autres types de données, copier directement
          else {
            merged[key] = val1;
          }
        }
      }
  
      return merged;
    }
  
    // Commencer la fusion à partir de la racine des objets
    return merge(content, trad);
}

function shuffleOptionsInScenario(scenario) {
    // Fonction utilitaire pour randomiser l'ordre des éléments dans un tableau
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Parcourir chaque clé du scénario
    for (const key in scenario) {
        if (scenario[key].hasOwnProperty('options')) {
            // Randomiser les options si elles existent
            scenario[key].options = shuffleArray(scenario[key].options);
        }
    }

    return scenario;
}
  

function scrollToBottom() {
   
    const element = document.getElementById('scroll-to-bottom');
    console.log('scroll')
    if (element) {
      element.scrollTo({
        top: element.scrollHeight -5 ,
        behavior: 'smooth'
      });

      console.log('element', element.scrollHeight)
    }
  }

const DynamicSVG = ({ src, width, height, className, color, ...props }) => {
    const [svgContent, setSvgContent] = useState('');
  
    useEffect(() => {
      const fetchSVG = async () => {
        try {
          const response = await fetch(src);
          const text = await response.text();
          setSvgContent(text);
        } catch (error) {
          console.error('Erreur lors de la récupération du SVG :', error);
        }
      };
  
      fetchSVG();
    }, [src]);
  
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: svgContent.replace(/width="[^"]*"/, 'width="100%"').replace(/height="[^"]*"/, 'height="100%"') }}
        style={{ color, width, height }}
        {...props}
      />
    );
  };

function getFileFromEmoji(emoji) {
    const emojiToFileMap = {
      "Angry with Fang.svg": ["😡", "😠"],
      "Awe.svg": ["😮", "😯"],
      "Blank.svg": ["😐", "😑"],
      "Calm.svg": ["😌", "😊"],
      "Cheeky.svg": ["😏", "😜"],
      "Concerned Fear.svg": ["😟", "😨"],
      "Concerned.svg": ["😕", "😟", "😅"],
      "Contempt.svg": ["😒", "🙄"],
      "Cute.svg": ["😊", "🥰"],
      "Cyclops.svg": ["👁️"],
      "Driven.svg": ["😤", "💪"],
      "Eating Happy.svg": ["😋", "🍽️"],
      "Explaining.svg": ["🧐", "🤔"],
      "Eyes Closed.svg": ["😑", "🙈"],
      "Fear.svg": ["😨", "😱"],
      "Hectic.svg": ["😵", "🤯"],
      "Loving Grin 1.svg": ["😍", "❤️"],
      "Loving Grin 2.svg": ["😎", "😏"],
      "Monster.svg": ["👹", "👺"],
      "Old.svg": ["👴", "👵"],
      "Rage.svg": ["😡", "😠"],
      "Serious.svg": ["😐", "😑"],
      "Smile Big.svg": ["😃", "😁"],
      "Smile LOL.svg": ["😂", "🤣"],
      "Smile Teeth Gap.svg": ["😁", "😬"],
      "Smile.svg": ["🙂", "😊"],
      "Solemn.svg": ["😔", "😞", "😢"],
      "Suspicious.svg": ["🤨", "🧐"],
      "Tired.svg": ["😴", "😪"],
      "Very Angry.svg": ["😠", "😡"]
    };
  
    for (const [file, emojis] of Object.entries(emojiToFileMap)) {
      if (emojis.includes(emoji)) {
        return file;
      }
    }
  
    return "Calm.svg";
  }
  

const InteractiveDialog = React.memo(({ content, selectedMode, currentUserChat, selectedScenario, trad, vocalMode}) => {
  
    const {workspace, profile, setShowBottomBar} = useStateContext()
    const lang = workspace?.lang
    const user = profile

    useEffect(() => {
        setShowBottomBar(false)
        return () => (
            setShowBottomBar(true)
        )
    }, [])
    const [interactionKey, setInteractionKey] = useState("start")
    const [endMessage, setEndMessage] = useState()
    const [messages, setMessages] = useState([])
    const [reaction, setReaction] = useState()
    const [preselection, setPreselection] = useState()
    const [playingSound, setPlayingSound] = useState()
    const [randomizedContent, setrandomizedContent] = useState([])
    const [audioMode, setAudioMode] = useState(false)
    const [isWriting, setIsWriting] = useState(false)
    const darkMode = false
    const [loadingMessage, setLoadingMessage] = useState()
    const [prompt, setPrompt] = useState()
    const [preprompt, setPreprompt] = useState("Réponds en allemand et corrige les fautes")

    useEffect(() => {
        const firstMessage = {role: "assistant", content: content?.["start"]?.text, trad: trad?.["start"]?.text }
        const preMsg = messages?.length ? messages : [firstMessage]
        const _messages= [...preMsg]
        setMessages(_messages)
    }, [])

    const lastAssistantMsgIndex = messages
    .map((message, index) => message.role === "assistant" ? index : -1)
    .filter(index => index !== -1)
    .pop();
  
    const filteredMessage = lastAssistantMsgIndex !== undefined ? messages.filter((_, index) => index !== lastAssistantMsgIndex) : messages;
    const lastAssistantMsg = messages[lastAssistantMsgIndex]



    const send = async (prompt, msgs, opt) => {
      
        try {
        console.log("send", prompt)

        const _messages= [...messages || [], {'role': 'assistant', content: "....", removeAfterLoad: true}, {'role': 'user', 'content': prompt, ug: true}]
        console.log('_message 1s', _messages)
        setMessages(_messages)
        console.log('_messages', _messages)
        const msgRequest =_messages.filter(e => !e.removeAfterLoad).slice(-10).map(e => ({role: e.role, content: (e.reaction || "") + " " + e.content}))
        const context = `Donne des réponses sans markdown. 
        Tu discutes avec le joueur, sur la thematique "${selectedScenario.name}", tu es un "${selectedScenario.people}", la discussion se passe dans un "${selectedScenario.place}"
        Reponds à l'utilisateur en ${lang}. Reagis avec des emotions comme un vrai humain ne soit pas toujours gentil et niais, tu peux t'enerver.
        S'il fait des erreurs n'hésite pas à les corriger.  Mais continuer à répondre quand même. 
        Soit proactif dans la conversation, et réponds comme si tu parlais à l'oral.
        ${selectedScenario?.prompt}
        Avant chacune des tes réponses ajoute un emoji qui correspond à ton état d'esprit et ta reaction par rapport à user.
        `
        msgRequest.unshift({role: "system", content: context })
        setTimeout(() => {scrollToBottom()}, 50)
    
        setLoadingMessage(true)

        console.log('msgRequest', msgRequest)
        console.log('user', user)
        const data = await gpt(msgRequest, true, null, {temp: 1.5}, user.id)
        setLoadingMessage(false)
        console.log("data", data)
        const _new_messages = [..._messages.filter(e => !e.removeAfterLoad)?.slice(-20) || [], {
          role: "assistant", 
          content: readReaction(data.choices[0]?.message?.content?.split('>>')?.[0]).textWithoutReaction,
          trad: data.choices[0]?.message?.content?.split('>>')?.[1],
          reaction: readReaction(data.choices[0]?.message?.content).reaction,
        }]
        setReaction(readReaction(data.choices[0]?.message?.content).reaction)
        setMessages(_new_messages)
        setPrompt()
        // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: _new_messages})
      } catch(e) {
        setLoadingMessage(false)
      }
    
       setTimeout(() => {scrollToBottom()}, 400)
    
      }

      const [showSpeak, setShowSpeak] = useState(false)

      

    


    useEffect(() => {
        const contentWithTrad = mergeContentWithTrad(content, trad)
        const randomized = shuffleOptionsInScenario(contentWithTrad)
        setrandomizedContent(randomized)
        console.log('contentWithTrad', contentWithTrad)
        console.log('--randomized!!!', randomized)
    }, [])



    const goodOption = randomizedContent?.[interactionKey]?.options?.find(e => e.points == 1) || {}
    useEffect(() => {
      setShowSpeak(false)
    }, [goodOption]) 
    

  return (
    <div className='flex flex-col  justify-between h-screen overflow-hidden '
   
    >

    
    <div  className='bg-white bg-center bg-cover flex flex-col justify-end  pt-[60px] grow overflow-scroll'  
    style={{backgroundImage: `url('${selectedScenario.bg}')`}} 
    >
        
    
    

     <div  id="scroll-to-bottom" className='bg-black/10  h-full rounded-xl  mx-2 p-2 overflow-scroll'>
        {filteredMessage?.map((mess, i) => <Message prevMess={filteredMessage[(i || 1) - 1]} selectedScenario={selectedScenario} classOther="!bg-white !border-none !text-amber-900 !text-sm" classUser='!bg-amber-50 !border-none !text-sm !text-amber-900' messages={messages} isVocal={mess.role == "user" ? null :  vocalMode} currentUserChat={currentUserChat} setMessages={setMessages}  darkMode={true} mess={mess}/>)}
       
    </div> 

   



    {!messages?.length && <div className='flex flex-col h-full items-center p-4 justify-center'><div className='text-amber-900 rounded-xl px-4 backdrop-blur py-2 bg-white/50 text-base text-center italic'>{content?.context}</div></div>}

    <div  className='flex flex-col grow justify-end'>
        <div  className='flex relative z-10   p-4 pt-0 min-h-[145px]  gap-2 items-end   overflow-hidden bg-cover' 
    // style={{backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/dialog_bg%2Fbg_test.png?alt=media&token=d0cdc6e5-713d-4073-a14b-e641385a1230')"}}
    >
            <div className='text-[40px] mb-10 rounded-full flex items-center  justify-center min-h-[50px] min-w-[50px] '>
                <div className='relative'>
                
                    <DynamicSVG
                     
                        src={`/images/UX/peeps/head/${selectedScenario.face}.svg`}
                        className="h-[70px] relative z-10 w-[70px] text-slate-500"
                    />
                    <img key={"react_"+reaction} src={"/images/UX/peeps/face/"+getFileFromEmoji(reaction || "🙂")} className='h-[58%] z-20 absolute top-[30%] left-[27%]' />
                    <img className='absolute h-[100px] top-[57%] left-[-10%]' src={`/images/UX/peeps/body/${selectedScenario.body}.svg`} />
                </div>
            </div>
            {selectedMode != "open"  ? <div onClick={async() => {
                setPlayingSound(lastAssistantMsg?.content)
                await speak({text: lastAssistantMsg?.content || endMessage, lang: lang})
                setPlayingSound(false)
            }} 
                
                className='text-[#391403] max-h-[140px]-- overflow-y-auto backdrop-blur text-[16px] px-4 py-2 rounded-2xl bg-white/90'>{ 
                    loadingMessage && <div className='flex'><div className=''><l-bouncy
                size="30"
                speed="1.75" 
                color="#607694" 
                ></l-bouncy></div></div>  }
                
                 {
                     vocalMode? 
                <div>
                {playingSound == lastAssistantMsg?.content ? 
                <l-waveform size="18" speed="1.6"   color="#2B1403" ></l-waveform> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                </svg>}
                </div>
                : <div> 
                  {lastAssistantMsg?.content != "...." && lastAssistantMsg?.content } {endMessage}

                {!endMessage && lastAssistantMsg?.content && lastAssistantMsg?.content != "...." && <div className='mt-2' onClick={async() => {
                setPlayingSound(lastAssistantMsg?.content)
                await speak({text: lastAssistantMsg?.content || endMessage, lang: lang})
                setPlayingSound(false)
                }} > {playingSound == lastAssistantMsg?.content ? 
                <l-waveform size="18" speed="1.6"   color="#2B1403" ></l-waveform> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
              </svg>
              }</div>}
            </div>}
                {/* <div className='opacity-50 italic text-xs'>{trad?.[interactionKey]?.text}</div> */}
              </div> : lastAssistantMsg?.content == "...." ? <div className='bg-amber-50 rounded-full px-4 py-2'><l-bouncy
                size="30"
                speed="1.75" 
                color="#2B1403" 
                ></l-bouncy></div> : lastAssistantMsg?.content && lastAssistantMsg?.content != "...." && 
                <Message  classOther="!bg-white !border-none !text-amber-900 !text-sm" classUser='!bg-amber-50 !border-none !text-sm !text-amber-900' messages={messages} selectedScenario={selectedScenario} isVocal={vocalMode} currentUserChat={currentUserChat} setMessages={setMessages} darkMode={true} mess={lastAssistantMsg}/>
                }
    </div>
    </div>
                    
                    </div>
                    <div className=' relative text-xl  relative z-10	backdrop-blur-3xl left-0 right-0'
>


    {/* <img className='absolute z-0 top-0 left-0  top-1 pointer-events-none  right-0 bottom-0 blur-[40px]' src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/dialog_bg%2Fbg_test.png?alt=media&token=d0cdc6e5-713d-4073-a14b-e641385a1230" /> */}
     <div onClick={() => {
        setInteractionKey('start'); 
        setEndMessage();
        setMessages([])
        console.log('content', content)
        }} className=' absolute top-1 pointer-events-none	 right-1 py-2 flex w-full  justify-end'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white/50 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
    </div>
        
        <div className='p-4 pb-8 z-10 border-t-2 border-amber-800/50 pattern-triangle relative rounded-t-xl -mt-2 relative bg-[#fff6d3]'>
        {endMessage && <div className='text-amber-900 text-center'>
            Terminé
    
           </div>}
        {selectedMode == "expression" && <div className='text-amber-900/90'>
          {/* <div className='text-xs opacity-50' onClick={() => console.log('randomizedContent?.[interactionKey]', randomizedContent?.[interactionKey])}>Prononce ta réponse en anglais</div> */}
          <div className='text-xs'><span className='opacity-[0.6]'>Prononce ce texte en {workspace.name}: </span>"{goodOption.speak_trad}"</div>
          {showSpeak ? <div onClick={() => setShowSpeak(false)} className='text-sm font-[700]'>{goodOption.speak}</div> : <div className='text-base mt-4 text-center' onClick={() => setShowSpeak(true)}>Voir le texte</div>}

          {/* <textarea className={`w-full grow mt-4  min-h-[80px] text-base mx-auto bg-white border-amber-900/20 hover:border-amber-800/30  border-2 border-b-4  text-slate-600  transition-all  rounded-xl p-2 pl-3 pr-16 `} id="answer">{}</textarea> */}
<SpeechToTextSimple3 referenceText={goodOption.speak} lang={workspace.lang} 
send={() => {
    setInteractionKey(goodOption.next); 
    setEndMessage(goodOption.end_sentence);
    setReaction(goodOption.reaction);
    setTimeout(() => { scrollToBottom()}, [10])
    setMessages([...messages || [],       
    {content: goodOption.speak, trad: goodOption.speak_trad, role: "user"}, 
    {role: "assistant", content: randomizedContent?.[goodOption.next]?.text, trad: trad?.[goodOption.next]?.text } ])

                      }} />


          </div>}
        {selectedMode == "open" && <div className='flex gap-2'>
                  {audioMode && <div className='p-2 w-full relative  ml-6 mb-2 rounded-xl relative'>
                      <div onClick={() => {setAudioMode(false)}} className='absolute mt-4 left-[-26px]'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-7 text-amber-800">
  <path fillRule="evenodd" d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z" clipRule="evenodd" />
</svg>
</div>                
                      <SpeechToTextSimple2  lang={workspace.lang} send={(text) => {
                          send(text, {audio: true})
                      }} />
                    </div>}
                  {!audioMode && <textarea 
                  onBlur={() => {setTimeout(() => {setIsWriting(false)}, 500)}} 
                  onFocus={() =>setIsWriting(true)} 
                  placeholder={'Ecris ton message '} 
                  style={{outline: "none",
                  height: countNewLines(prompt) > 0 ? (countNewLines(prompt))* 25 + 45 : 46}} 
                  className={`max-w-[800px] grow ${isWriting ? "w-full" : "mr-4"} text-base mx-auto bg-white border-amber-900/20 hover:border-amber-800/30  border-2 border-b-4  text-slate-600  transition-all  rounded-xl p-2 pl-3 pr-16 `} id="prompt">{prompt}</textarea>
                }
                {isWriting && !audioMode && <div 
                onClick={(e) => {
                  e.stopPropagation()
                  const textArea = document.querySelector('#prompt')
                  console.log('textArea', textArea)
                  console.log('textArea target', textArea?.value)
                  setMessages([...messages || [], {role: "assistant", content: randomizedContent?.[interactionKey]?.text, trad: trad?.[interactionKey]?.text }])
                  send(textArea?.value, )
                  textArea.value = ""

                  
                  
                  }} className='text-amber-700 text-base fredoka absolute right-[26px] bottom-[43px]'>Envoyer</div>}
              
                {!isWriting && !audioMode && <svg onClick={() => setAudioMode(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`bg-amber-700 p-2 border-2 border-b-4 border-black/70 rounded-full h-[46px]  bottom-[24px] right-[16px]`}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                </svg>}
              </div>}
        {selectedMode == "interactif" && randomizedContent?.[interactionKey]?.options?.map((e,i) => <div className='flex justify-end items-center'><div 
        onClick={async() => {
            if (!vocalMode) {
                setInteractionKey(e.next); 
                setEndMessage(e.end_sentence);
                setReaction(e.reaction);
                setTimeout(() => { scrollToBottom()}, [10])
                if (e.points == 1) {
                    mp3_valid.play()
                }
                if (e.points == 0) {
                    mp3_valid.play()
                }
                if (e.points == -1) {
                    mp3_bad.play()
                }
                setMessages([...messages || [], 
                    
                     {content: e.speak, trad: e.speak_trad, role: "user"}, 
                     {role: "assistant", content: randomizedContent?.[e.next]?.text, trad: trad?.[e.next]?.text } ])
              
            } else {
                setPreselection(e)
                setPlayingSound(e.speak)
                await speak({text: e.speak, lang: lang, variation: "A"})
                
                setPlayingSound(false)
                const nextSound = randomizedContent?.[e.next]?.text
                if (nextSound) {
                    setPlayingSound(nextSound)
                    await speak({text: nextSound, lang: lang})
                    
                    setPlayingSound(false)
                }
                
            }
           
            ;}} 
        className={`px-2 mt-1  ${preselection?.speak == e.speak ? "bg-amber-500 text-slate-900" : "bg-amber-50 text-slate-900"} ${vocalMode ? "w-[130px]" : ""}  text-right  rounded-xl py-1 border-2 border-b-4 border-black/70`}>
                <div className='text-base'>{vocalMode ?  playingSound == e.speak ? <l-waveform size="18" speed="1.6"   color="#2B1403" ></l-waveform> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                </svg> : e.speak}</div>
                {/* <div>{e.speak_trad}</div> */}
                {/* <div className='text-white/40 text-xs'><span>{e.points}pt</span> → {e.next} - {e.reaction} <span className='text-red-400'>{e.end_sentence}</span></div> */}
            </div></div>
            )}  
            {selectedMode == "interactif" && vocalMode && !endMessage && randomizedContent?.[interactionKey]?.options?.length && <div className='justify-end  flex items-center'><div className={` ${!preselection?.speak ? "opacity-50 pointer-events-none" : ""} text-slate-900 w-[100px] flex justify-center bg-amber-50 px-2 mt-1 rounded-xl py-1 border-2 border-b-4 border-black/70`} onClick={() => {
                  setInteractionKey(preselection.next); 
                  setEndMessage(preselection.end_sentence);
                  setReaction(preselection.reaction);
                  setTimeout(() => { scrollToBottom()}, [100])
                  if (preselection.points == 1) {
                    mp3_valid.play()
                }
                if (preselection.points == 0) {
                    mp3_valid.play()
                }
                if (preselection.points == -1) {
                    mp3_bad.play()
                }
                  setMessages([...messages || [], 
                    {content: preselection?.speak, trad: preselection.speak_trad, role: "user"}, 
                    {role: "assistant", content: randomizedContent?.[preselection.next]?.text, trad: trad?.[preselection.next]?.text } ])
                    
            }}>Valider</div></div>}
          
        </div>
      </div>
    </div>
   );
}, (prevProps, nextProps) => {
    return prevProps.content === nextProps.content && prevProps.selectedMode === nextProps.selectedMode

});

export {InteractiveDialog, DynamicSVG};
